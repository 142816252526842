import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Notification } from "../types";

const initialState: Notification[] = [];

export const NotificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    onNotifications: (state, action: PayloadAction<Notification[]>) => {
      const newState = [...state];
      for (let item of action.payload) {
        const index = newState.findIndex((i) => i.uid === item.uid);
        if (index === -1) {
          newState.push(item);
        } else {
          newState[index] = item;
        }
      }
      return newState.sort((a, b) => b.created_at - a.created_at);
    },
  },
});

export const { onNotifications } = NotificationSlice.actions;
