import { useEffect, useState } from "react";
import { InvoiceData, UserInfos } from "../../redux/types";

const sumHt = (acc: number, cur: InvoiceData) =>
  acc + parseFloat(String(cur.montant_ht));
const sumTva = (acc: number, cur: InvoiceData) =>
  acc + parseFloat(String(cur.tva));

export function useDashboard(users: UserInfos[], invoices: InvoiceData[]) {
  const [data, setData] = useState({
    total_tva_emise: 0,
    total_tva_recuperable: 0,
    total_facture_emise: 0,
    total_facture_depense: 0,
    total_user_count: 0,
    total_facture_count: 0,
  });

  useEffect(() => {
    const finalData = {
      total_tva_emise: 0,
      total_tva_recuperable: 0,
      total_facture_emise: 0,
      total_facture_depense: 0,
      total_user_count: 0,
      total_facture_count: 0,
    };
    let deps: InvoiceData[] = [];
    let emises: InvoiceData[] = [];
    for (let i of invoices) {
      if (i.type_depense === "Émise") emises.push(i);
      else deps.push(i);
    }

    finalData.total_tva_emise = emises.reduce(sumTva, 0);
    finalData.total_tva_recuperable = deps.reduce(sumTva, 0);
    finalData.total_facture_emise = emises.reduce(sumHt, 0);
    finalData.total_facture_depense = deps.reduce(sumHt, 0);
    finalData.total_user_count = users.length;
    finalData.total_facture_count = invoices.length;
    setData(finalData);
  }, [users.length, invoices.length]);

  return {
    data,
    loading: data.total_facture_count === 0,
  };
}
