import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageContact } from "../types";

export type State = MessageContact[];

const initialState: State = [];

export const MessageContactSlice = createSlice({
  name: "messages_contact",
  initialState,
  reducers: {
    onMessageContact: (state, action: PayloadAction<State>) => {
      const newState = [...state];
      for (const i of action.payload) {
        const index = newState.findIndex((o) => o.id === i.id);
        if (index === -1) {
          newState.push(i);
        } else {
          newState[index] = i;
        }
      }
      return newState.sort(
        (a, b) => parseInt(b.created_at) - parseInt(a.created_at)
      );
    },
  },
});

export const { onMessageContact } = MessageContactSlice.actions;

export default MessageContactSlice.reducer;
