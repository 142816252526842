import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { UserInfos } from "../types";
const initialState: UserInfos[] = [];

export const UsersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    onUsers: (state, action: PayloadAction<UserInfos[]>) => {
      const newState = [...state];
      for (let item of action.payload) {
        if (!item.created_at) {
          item.created_at = moment()
            .subtract(1, "year")
            .toDate()
            .getTime()
            .toString();
        }
        const index = newState.findIndex((i) => i.uid === item.uid);
        if (index === -1) {
          newState.push(item);
        } else {
          newState[index] = item;
        }
      }
      return _.sortBy(newState, "created_at").reverse();
    },
    onDeleteUsers: (state, action: PayloadAction<string[]>) => {
      return state.filter((f) => !action.payload.includes(f.uid as string));
    },
  },
});

export const { onUsers, onDeleteUsers } = UsersSlice.actions;
