import { useAppSelector } from "../../../redux/store";
import { UserInfos } from "../../../redux/types";
import { Authorisations } from "../../../utils/Constantes";
import UseCrudAdmin from "../../TableauDebord/Admin/ParametreAdmin/request/UseCrudAdmin";
import ErrorMessage from "../../common/ErrorMessage";
import CloseModalBtn from "../../shared/CloseModalBtn";

const AddAdminModal = ({
  modalId,
  btnId,
  func,
  admin,
}: {
  modalId: string;
  btnId: string;
  func: any;
  admin?: UserInfos;
}) => {
  const { register, errors, onSubmit, isLoading, handleReset } = UseCrudAdmin(
    modalId,
    btnId,
    func,
    admin
  );
  const { userInfos } = useAppSelector((s) => s?.auth);

  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content text-start">
        <div className="modal-header py-2 flex-m">
          <h5 className="admin-modal-title text-start">
            {admin ? "Modifier" : "Ajouter"} un administrateur
          </h5>
          <CloseModalBtn onClick={() => handleReset()} />
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                {/* <div className="col-md-12 mb-3">
                  <div className="d-flex flex-column align-items-center avatar-container">
                    <div className="mb-auto profile-image-admin">
                      <div className="profile-avatar-container">
                        <img
                          src={
                            avatar
                              ? URL.createObjectURL(avatar)
                              : admin?.avatar || Avatar
                          }
                          alt="avatar"
                        />
                        <span style={loading ? { bottom: "0%" } : {}}>
                          <i className="fas fa-camera"></i>
                          <p>
                            {loading ? (
                              <i
                                className="spinner-border spinner-border-sm  me-1 d-inline-block"
                                role="status"
                              ></i>
                            ) : (
                              "Changer"
                            )}
                          </p>
                          <input
                            type="file"
                            onChange={(e: any) => {
                              let file = e.target.files[0];
                              handleChangeAvatar(file);
                            }}
                            accept="image/*"
                            disabled={loading}
                            name="avatar"
                            id="file_up"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="tel"
                      className="form-label form-label-modal-custom"
                    >
                      Prenom
                    </label>
                    <input
                      type="text"
                      placeholder="Prenom"
                      className="form-control form-control-modal-custom"
                      id="prenom"
                      {...register("prenom")}
                    />
                    <ErrorMessage message={errors?.prenom?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="tel"
                      className="form-label form-label-modal-custom"
                    >
                      Nom
                    </label>
                    <input
                      type="text"
                      placeholder="Nom"
                      className="form-control form-control-modal-custom"
                      id="nom"
                      {...register("nom")}
                    />
                    <ErrorMessage message={errors?.nom?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="email"
                      className="form-label form-label-modal-custom"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder="Example@gmail.com"
                      className="form-control form-control-modal-custom"
                      id="email"
                      disabled={!!admin}
                      {...register("email")}
                    />
                    <ErrorMessage message={errors?.email?.message} />
                  </div>
                </div>
                {admin?.uid !== userInfos?.uid ? (
                  <div className="col-md-12 mt-3">
                    <div className="form-group crud-form-group content">
                      <label
                        htmlFor="fixe"
                        className="form-label form-label-modal-custom"
                      >
                        Accès
                      </label>
                      <div className="container-radio-input">
                        <div className="form-radio-input radio-input">
                          <div className="row mt-2">
                            {Authorisations?.map((item, i) => (
                              <div className="col-md-4 test" key={i}>
                                <div className="d-flex justify-content-start align-items-center mb-2 gap-1 permissions-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input-check form-check-input"
                                    // defaultChecked={permissions?.includes(item?.value)}
                                    {...register(`permissions.${i}`)}
                                    defaultChecked={
                                      admin
                                        ? admin?.permissions?.length &&
                                          admin?.permissions?.includes(
                                            item?.value
                                          )
                                          ? true
                                          : false
                                        : true
                                    }
                                    value={item?.value}
                                    // onChange={(e) => handleChange(e, i)}
                                    id={`checkbox${i}`}
                                  />
                                  <label
                                    className="custom-form-label mb-0"
                                    htmlFor={`checkbox${i}`}
                                  >
                                    {item?.label}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="container-btn-modal row mt-4">
                <div className="col-md-4">
                  <button
                    className="btn auth-submit-btn"
                    type="submit"
                    disabled={isLoading}
                  >
                    {!isLoading ? (
                      admin ? (
                        "Modifier"
                      ) : (
                        "Envoyer"
                      )
                    ) : (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>Enregistrement...</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdminModal;
