import {
  User,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import { auth } from "../../firebase";
import { updateUserAsync } from "./user.api";

export function authListener(cb: (user: User | null) => any) {
  return onAuthStateChanged(auth, cb);
}

export function updateUserEmail(uid?: string, email?: string) {
  if (auth.currentUser) {
    if (
      uid === auth.currentUser.uid &&
      email &&
      email !== auth.currentUser.email
    )
      return updateEmail(auth.currentUser, email);
  }
  return true;
}

export async function loginAsync(email: string, password: string) {
  return await signInWithEmailAndPassword(auth, email, password);
}
export async function registerAsync(email: string, password: string) {
  return await createUserWithEmailAndPassword(auth, email, password);
}
export function logoutAsync() {
  return signOut(auth);
}

export function sendPasswordResetLinkAsync(email: string) {
  return sendPasswordResetEmail(auth, email);
}

export async function updatePasswordAsync(password: string, userId: string) {
  if (auth.currentUser) {
    const res = await updatePassword(auth.currentUser, password);
    await updateUserAsync(userId, { isDefaultPassword: false });
    return res;
  }
  return null;
}

export default auth;
