import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/store";
import { useAddContent } from "../../../../utils/api/user.api";
import { HtmlInput } from "../../../common/HtmlInput";

const GestionCgu = () => {
  return <ContentForm />;
};

export default GestionCgu;

export function ContentForm() {
  const data = useAppSelector((s) => s.content);
  const [content, setContent] = useState("");
  const { loading, onAdd } = useAddContent(() => null);
  useEffect(() => {
    if (data) {
      setContent(data.html);
    }
  }, [data]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onAdd(content);
      }}
    >
      <div className="form-group">
        <HtmlInput name="content" data={content} onChange={setContent} />
      </div>
      <div className="container-btn-modal row mt-4">
        <div className="col-md-4">
          <button
            className="btn auth-submit-btn"
            type="submit"
            disabled={loading}
          >
            {!loading ? (
              "Enregistrer"
            ) : (
              <>
                <span
                  className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                  role="status"
                ></span>
                <span>Enregistrement...</span>
              </>
            )}
          </button>
        </div>
      </div>
    </form>
  );
}
