import { deleteDoc, doc } from "firebase/firestore";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { AiFillEye } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import Swal from "sweetalert2";
import { db } from "../../../../firebase";
import { useAppSelector } from "../../../../redux/store";
import { Releve, UserInfos } from "../../../../redux/types";
import { getReleves } from "../../../../utils/api/user.api";
import ListeReleverComtabiliteTableSkeleton from "./ListeReleverComtabiliteTableSkeleton";
function ListeReleverComtabiliteTable() {
  const users = useAppSelector((s) => s.users2);

  const [data, setData] = useState<Releve[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const onGetReleves = useCallback(() => {
    const ids = users.map((c) => c.uid as string);
    if (ids.length > 0) {
      setIsLoading(true);
      getReleves(ids).then((data) => {
        setIsLoading(false);
        setData(
          data.map((d) => ({
            ...d,
            userId: users.find((u) => u.uid === String(d.userId)) as UserInfos,
          }))
        );
      });
    }
  }, [users.length]);

  useEffect(() => {
    onGetReleves();
  }, [onGetReleves]);

  const onDeleteReleve = async (uid: string) => {
    const docRef = doc(db, "releves", uid);
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: "#02B4C0",
      confirmButtonColor: "#02B4C0",
      preConfirm: () => {
        return deleteDoc(docRef);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result: any) => {
      if (result?.value) {
        onGetReleves();
        await Swal.fire({
          icon: "success",
          title: `Relevé supprimé avec succès!`,
          iconColor: "#02B4C0",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const actionFormatter = (cell, row) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <a
              href={row.file}
              target="_blank"
              title="voir"
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              rel="noreferrer"
            >
              <AiFillEye />
            </a>
          </div>
          <div className="container-btn-action-icon-modal">
            <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              style={{ color: "#E53E3E" }}
              onClick={() => onDeleteReleve(row.uid)}
            >
              <BsTrashFill />
            </button>
          </div>
        </div>
      </>
    );
  };

  const options = {
    pageStartIndex: 1,
    sizePerPage: 50,
    hidePageListOnlyOnePage: true,
    hideSizePerPage: true,
  };

  const columns = [
    {
      dataField: "userId",
      text: "Client",
      filter: textFilter({ placeholder: "Chercher..." }),
      filterValue: (user) => `${user.prenom} ${user.nom}`,
      formatter: (user) => `${user.prenom} ${user.nom}`,
    },
    {
      dataField: "date",
      text: "Date",
      filter: textFilter({ placeholder: "Chercher..." }),
      formatter: (date) => moment(date).format("DD MMMM YYYY"),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA: Releve, rowB: Releve) => {
        if (order === "desc") {
          return rowB.date - rowA.date;
        }
        return rowA.date - rowB.date;
      },
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <ListeReleverComtabiliteTableSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          bordered={true}
          sort={{ dataField: "date", order: "desc" }}
          condensed={false}
          responsive={true}
          wrapperClasses="table-responsive admin-table"
          noDataIndication={() => "Il ya pas de données"}
          rowStyle={{ cursor: "pointer" }}
          pagination={paginationFactory(options)}
          filter={filterFactory()}
        />
      )}
    </>
  );
}

export default ListeReleverComtabiliteTable;
