import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/store";
import { InvoiceData } from "../../../../redux/types";
import ExcelExport from "../../../../utils/Excelexport";
import { getInvoices } from "../../../../utils/api/invoice.api";
import { getExportData } from "../../../../utils/api/user.api";
import FacturesTable from "../../../tables/admin/FacturesTable/FacturesTable";
import "./ListeFacturesSuperAdmin.css";

const ListeFacturesSuperAdmin = () => {
  const categories = useAppSelector((s) => s.categories);
  const [invoices, setInvoices] = useState<InvoiceData[]>([]);
  const [filteredData, setFilteredData] = useState<InvoiceData[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [word, setWord] = useState<string>("");
  const auth = useAppSelector((s) => s?.auth);
  useEffect(() => {
    getData();
  }, [auth]);

  const getData = () => {
    getInvoices().then((res: any) => {
      setInvoices(res?.data);

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    });
  };
  useEffect(() => {
    if (word?.length) {
      setFilteredData(
        invoices?.filter(
          (item) =>
            item?.number?.includes(word) ||
            (item?.userId?.prenom + " " + item?.userId?.nom)
              ?.toLowerCase()
              ?.includes(word?.toLowerCase())
        )
      );
    } else {
      setFilteredData(invoices);
    }
  }, [word, invoices]);

  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="admin-page-title-header-container">
          <div className="fast-facture-dash-locataire-requetes-header">
            <h4 className="fast-facture-dash-header-titre">Factures</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="bg-white p-3">
            <div className="flex-sb align-items-center mb-4">
              <div className="tab-dash-admin-filter-container">
                <form className="row g-3 tab-dash-admin-filter-form">
                  <div className="col-auto">
                    <label
                      htmlFor="filtreLogement"
                      className="tab-dash-admin-filter-label"
                    >
                      Filtrer
                    </label>
                  </div>
                  <div className="col-auto">
                    <input
                      type="text"
                      className="form-control tab-dash-admin-filter-input"
                      id="filtreLogement"
                      placeholder="Entrer un nom"
                      onChange={(e) => {
                        setWord(e.target.value);
                      }}
                      value={word}
                    />
                  </div>
                </form>
              </div>
              <ExcelExport
                getFileData={() => getExportData(invoices, categories)}
                fileName={`Factures_${moment(new Date()).format(
                  "DD-MM-YYYY-HH-mm-ss"
                )}`}
              />
            </div>
            <FacturesTable
              data={filteredData}
              isLoading={isLoading}
              func={getData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeFacturesSuperAdmin;
