import { useAppSelector } from "../../../redux/store";
import ErrorMessage from "../../common/ErrorMessage";
import CloseModalBtn from "../../shared/CloseModalBtn";
import UseRequestPassword from "../../TableauDebord/Admin/Profil/request/UseRequestPassword";

const ChangePasswordModal = ({
  modalId = "changePassword",
}: {
  modalId?: string;
}) => {
  const isDefaultPassword = useAppSelector(
    (s) => s.auth?.userInfos?.isDefaultPassword
  );
  const { register, isLoading, onSubmit, errors, handleReset } =
    UseRequestPassword(modalId);

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="admin-modal-title text-start" id="changePasswordLabel">
            {isDefaultPassword
              ? "Veuillez changer votre mot de passe"
              : "Changer mon mot de passe"}
          </h5>
          <CloseModalBtn onClick={() => handleReset()} />
        </div>
        <div className="modal-body">
          <form onSubmit={onSubmit}>
            <div className="mb-3">
              <label
                htmlFor="new_password"
                className="form-label form-label-modal-custom"
              >
                NOUVEAU MOT DE PASSE
              </label>
              <input
                type="password"
                className="form-control form-control-modal-custom"
                id="new_password"
                {...register("password")}
              />
              <ErrorMessage message={errors?.password?.message} />
            </div>
            <div className="mb-3">
              <label
                htmlFor="passwordConfirm"
                className="form-label form-label-modal-custom"
              >
                CONFIRMER NOUVEAU MOT DE PASSE
              </label>
              <input
                type="password"
                className="form-control form-control-modal-custom"
                id="passwordConfirm"
                {...register("password_confirm")}
              />
              <ErrorMessage message={errors?.password_confirm?.message} />
            </div>
            <div className="gap-3 flex-r align-center mt-4">
              <button
                type="button"
                className="btn btn-cancelled"
                data-bs-dismiss="modal"
                id="btn-close"
                onClick={() => handleReset()}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn auth-submit-btn"
                disabled={isLoading}
              >
                {!isLoading ? (
                  "Terminer"
                ) : (
                  <>
                    <i
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></i>
                    <span>Enregistrement...</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ChangePasswordModal;
