import React from "react";
import avatar from "../../../../assets/appImages/user.png";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import TabsInfoEntreprise from "./DetailEntrepriseSTeps/TabsInfoEntreprise";
import TabsUsersEntreprise from "./DetailEntrepriseSTeps/TabsUsersEntreprise";
import { useNavigate } from "react-router-dom";

const steps = [
  { id: "information", Component: TabsInfoEntreprise },
  { id: "users", Component: TabsUsersEntreprise },
];

const tabs = ["Informations entreprise", "Clients (50)"];

const DetailEntreprise = () => {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const navigate = useNavigate();

  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="admin-page-title-header-container flex-sb align-items-center">
          <div className="fast-facture-dash-locataire-requetes-header">
            <h4
              className="fast-facture-dash-header-titre linkable"
              onClick={() => navigate(-1)}
            >
              Entreprises &gt;
              <span style={{ fontSize: 16, fontWeight: 400 }}>Volkeno</span>
            </h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="bg-white p-3">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex flex-column align-items-center py-4 mb-4 fast-facture-user-avatar-container">
                  <div className="fast-facture-dash-param-title pt-2">
                    Volkeno
                  </div>
                </div>
              </div>
            </div>
            <div className="container-tabs-user">
              <ul className="tabs-container pt-5 tabs-container-agence">
                {tabs.map((tab, key) => (
                  <li
                    className={`tab-item  ${
                      index === key && "tab-active-item"
                    }`}
                    key={key}
                    onClick={() => go(key)}
                    role="button"
                  >
                    {tab}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="container-page-tabs-user mb-3">
          <div className="bg-white p-3">
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailEntreprise;
