import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { categorySlice } from "./slice/categorySlice";
import { ContentSlice } from "./slice/content.slice";
import { DashboardSlice } from "./slice/dashboard.slice";
import { InvoiceSlice } from "./slice/invoiceSlice";
import { MessageContactSlice } from "./slice/message-contact.slice";
import { NotificationSlice } from "./slice/notification.slice";
import { userSlice } from "./slice/user.slice";
import { UsersSlice } from "./slice/users.slice";
import { Users2Slice } from "./slice/users2.slice";

const store = configureStore({
  reducer: {
    [userSlice.name]: userSlice.reducer,
    [ContentSlice.name]: ContentSlice.reducer,
    [Users2Slice.name]: Users2Slice.reducer,
    [DashboardSlice.name]: DashboardSlice.reducer,
    [InvoiceSlice.name]: InvoiceSlice.reducer,
    [categorySlice.name]: categorySlice.reducer,
    [UsersSlice.name]: UsersSlice.reducer,
    [MessageContactSlice.name]: MessageContactSlice.reducer,
    [NotificationSlice.name]: NotificationSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export function useAppSelector<T>(cb: (root: RootState) => T) {
  return useSelector(cb);
}
export default store;
