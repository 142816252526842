import React, { useEffect } from "react";
import { IoMdAdd } from "react-icons/io";
import { useAppSelector } from "../../../../redux/store";
import { UserInfos } from "../../../../redux/types";
import { isAdminUser } from "../../../../utils/utils";
import ListeUsersTable from "../../../tables/admin/ListeUsersTable/ListeUsersTable";
import "./ListeUsers.css";

const ListeUsers = () => {
  const users = useAppSelector((s) => s.users);
  const [data, setData] = React.useState<UserInfos[]>([]);
  useEffect(() => {
    setData(
      users.filter((f) => {
        return !isAdminUser(f) && !f.is_comptable;
      })
    );
  }, [users]);
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="admin-page-title-header-container flex-sb align-items-center">
          <div className="fast-facture-dash-locataire-requetes-header">
            <h4 className="fast-facture-dash-header-titre">Clients</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="bg-white p-3 min-h-80">
            <div className="content-btn-add-theme flex-r">
              <button
                className="btn btn-add-theme"
                data-bs-toggle="modal"
                data-bs-target="#addUserModal"
                id="BtnAdminAddUser"
              >
                <IoMdAdd />
                <span className="ps-2">Ajouter un client</span>
              </button>
            </div>
            <ListeUsersTable is_comptable={false} data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeUsers;
