import {
  IoPaperPlaneOutline,
  IoPeopleOutline,
  IoPersonOutline,
} from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { NavLink } from "react-router-dom";
import Logo2 from "../../../../assets/appImages/Fast_Facture.png";
import Logo from "../../../../assets/appImages/logo-init.png";
import { onLogout } from "../../../../redux/slice/user.slice";
import { useAppDispatch } from "../../../../redux/store";
import { logoutAsync } from "../../../../utils/api/auth.api";

function SideBarComptableContent() {
  const dispatch = useAppDispatch();
  const Logout = () => {
    dispatch(onLogout());
    logoutAsync();
  };
  return (
    <div className="hoverable" id="scrollbar">
      <div className="content">
        <div className="container-user-connect-info pb-3">
          <div className="container-img-user-connect-nav-menu">
            <img
              className="img-user-connect-navmenu"
              src={Logo}
              alt="user connect"
            />
          </div>
          <div className="container-info-user-connect-navmenu ps-2">
            <div className="ligne-logo"></div>
            <div className="container-other-text-logo ms-2">
              <img className="img-others-text" src={Logo2} alt="user connect" />
            </div>
          </div>
        </div>
        <ul className="nav nav-items-custom flex-column">
          {/* ROUTE COMPTABILITE */}

          <li className="nav-item nav-item-vertical-custom position-relative">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/comptabilite/utilisateurs"
            >
              <IoPeopleOutline />
              <span className="hiddenable-navlink-label">Clients </span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom position-relative">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/comptabilite/releves-bancaires"
            >
              <IoPaperPlaneOutline />
              <span className="hiddenable-navlink-label">
                Relevés bancaires
              </span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/comptabilite/profil"
            >
              <IoPersonOutline />
              <span className="hiddenable-navlink-label">Profil</span>
            </NavLink>
          </li>

          {/* ROUTE COMPTABILITE */}
          <li className="nav-item nav-item-vertical-custom">
            <button
              className="btn nav-link nav-link-vertival-custom"
              onClick={() => Logout()}
            >
              <MdLogout />
              <span className="hiddenable-navlink-label">Deconnexion</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideBarComptableContent;
