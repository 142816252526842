import { IoMdAdd } from "react-icons/io";
import { useActiveTab } from "../../../../utils/utils";
import AddAdminModal from "../../../modal/admin/AddAdminModal";
import ListeAdminsTable from "../../../tables/admin/ListeAdminsTable/ListeAdminsTable";
import { CategoriesTab } from "./CategorieTab";
import GestionCgu from "./GestionCgu";
import "./ParametreAdmin.css";

const tabs = ["Catégories", "Administrateurs", "Politique de confidentialité"];

const ParametreAdmin = () => {
  const { active, handleActiveTab } = useActiveTab();
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="admin-page-title-header-container flex-sb align-items-center">
          <div className="fast-facture-dash-locataire-requetes-header">
            <h4 className="fast-facture-dash-header-titre">Paramètres</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="bg-white p-3 min-h-80">
            <ul className="tabs-list px-0">
              {tabs?.map((tab, i) => (
                <li
                  className={`tab-item ${active === i ? "active" : ""}`}
                  key={i}
                  onClick={() => handleActiveTab(i)}
                  role="button"
                >
                  {tab}
                </li>
              ))}
            </ul>
            <div className="tabs-content">
              {active === 0 && <CategoriesTab />}
              {active === 1 && <AdminList />}
              {active === 2 && <GestionCgu />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function AdminList() {
  return (
    <>
      <div className="content-btn-add-theme flex-r">
        <button
          className="btn btn-add-theme"
          data-bs-toggle="modal"
          data-bs-target="#AddAdminModal"
          id="btnAdminAddAdmin"
        >
          <IoMdAdd />
          <span className="ps-2">Ajouter un administrateur</span>
        </button>
      </div>
      <ListeAdminsTable />
      <div
        className="modal fade"
        id="AddAdminModal"
        aria-labelledby="AddAdminModalLabel"
        aria-hidden="true"
      >
        <AddAdminModal
          modalId="AddAdminModal"
          func={() => {}}
          btnId="btnAdminAddAdmin"
        />
      </div>
    </>
  );
}

export default ParametreAdmin;
