import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { AiFillEye } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../redux/store";
import { UserInfos } from "../../../../redux/types";
import { deleteUser } from "../../../../utils/api/user.api";
import { formatAmout } from "../../../../utils/formatter";
import AddUserModal from "../../../modal/admin/AddUserModal";
function ListeUsersTable({
  data,
  is_comptable,
}: {
  data?: UserInfos[];
  is_comptable: boolean;
}) {
  const { userInfos } = useAppSelector((s) => s?.auth);

  const onDeleteUser = async (uid) => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet client ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: "#02B4C0",
      confirmButtonColor: "#02B4C0",
      preConfirm: () => {
        return deleteUser(uid);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.value?.data?.ok) {
        Swal.fire({
          icon: "success",
          title: `Client supprimé avec succès!`,
          iconColor: "#02B4C0",
          showConfirmButton: false,
          timer: 2000,
        });
      } else if (result?.value?.error) {
        Swal.fire({
          icon: "error",
          title: "Une erreur est survenue!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const actionFormatter = (cell, row) => {
    let route = `utilisateur/${cell}`;
    if (is_comptable) {
      route = `comptable/${cell}`;
    }
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={
                userInfos?.is_admin
                  ? `/admin/${route}`
                  : userInfos?.is_superadmin
                  ? `/super-admin/${route}`
                  : `/comptabilite/utilisateur/${cell}`
              }
              state={row}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink>
          </div>

          <div className="container-btn-action-icon-modal">
            <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              style={{ color: "#E53E3E" }}
              onClick={() => onDeleteUser(cell)}
            >
              <BsTrashFill />
            </button>
          </div>
        </div>
      </>
    );
  };

  const options = {
    pageStartIndex: 1,
    sizePerPage: 50,
    hidePageListOnlyOnePage: true,
    hideSizePerPage: true,
  };

  let columns = [
    {
      dataField: "nom",
      text: "Société/Nom",
      filter: textFilter({ placeholder: "Chercher..." }),
    },
    {
      dataField: "prenom",
      text: "Agence/Prénom",
      filter: textFilter({ placeholder: "Chercher..." }),
    },
    {
      dataField: "email",
      text: "Email Entreprise",
      filter: textFilter({ placeholder: "Chercher..." }),
    },
    {
      dataField: "email_comptable",
      text: "Email Comptable",
      filter: textFilter({ placeholder: "Chercher..." }),
    },
    {
      dataField: "abonnement",
      text: "Abonnement",
      style: { textAlign: "center" },
      // @ts-ignore
      formatter: (cell: any, row: any) => {
        if (cell === "abn0") return "Gratuit";
        if (cell === "abn") return formatAmout(10);
        if (cell === "abn2") return formatAmout(30);
        if (cell === "abn3") return formatAmout(50);
        return "";
      },
    },
    {
      dataField: "uid",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  if (is_comptable) {
    columns = [
      {
        dataField: "nom",
        text: "Nom",
        filter: textFilter({ placeholder: "Chercher..." }),
      },
      {
        dataField: "prenom",
        text: "Prénom",
        filter: textFilter({ placeholder: "Chercher..." }),
      },
      {
        dataField: "email",
        text: "Email comptable",
        filter: textFilter({ placeholder: "Chercher..." }),
      },
      {
        dataField: "uid",
        text: "Actions",
        style: { textAlign: "center" },
        formatter: (cell: any, row: any) => actionFormatter(cell, row),
      },
    ];
  }

  return (
    <>
      <BootstrapTable
        keyField="uid"
        data={data}
        columns={columns}
        bordered={true}
        condensed={false}
        responsive={true}
        wrapperClasses="table-responsive admin-table"
        noDataIndication={() => "Aucune donnée disponible"}
        pagination={paginationFactory(options)}
        filter={filterFactory()}
      />
      {!is_comptable && (
        <div
          className="modal fade"
          id="addUserModal"
          data-bs-backdrop="static"
          aria-labelledby="addUserModalLabel"
          aria-hidden="true"
        >
          <AddUserModal
            func={() => null}
            modalId="addUserModal"
            btnId="BtnAdminAddUser"
          />
        </div>
      )}
    </>
  );
}

export default ListeUsersTable;
