import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../../redux/store";
import { UserInfos } from "../../../../../redux/types";
import { updatePasswordAsync } from "../../../../../utils/api/auth.api";
import { cleannerError, onHide } from "../../../../../utils/utils";

type UpdatePasswordFormData = Pick<UserInfos, "password" | "password_confirm">;
function UseRequestPassword(modalId: string) {
  const userId = useAppSelector((s) => s.auth?.userInfos?.uid);
  const validationSchema = yup.object().shape({
    password: yup.string().required().label("Mot de passe").min(6),
    password_confirm: yup
      .string()
      .required()
      .oneOf(
        [yup.ref("password"), null],
        "Les mots de passe ne correspondent pas"
      )
      .label("La confirmation"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<UpdatePasswordFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);

  const handleReset = () => {
    reset();
  };

  const onSubmit = useCallback(
    async (data: UpdatePasswordFormData) => {
      setIsLoading(true);
      try {
        
        await updatePasswordAsync(data.password, userId as string);
        Swal.fire({
          icon: "success",
          title: "Mot de passe modifié avec succès!",
          showConfirmButton: false,
          iconColor: "#02B4C0",
          timer: 2000,
        });
        onHide(modalId, "btnAdminEditPasswor");
      } catch (ex: any) {
        if (ex.code === "auth/requires-recent-login") {
          Swal.fire({
            icon: "error",
            title:
              "Veuillez vous déconnecter et vous reconnecter pour modifier votre mot de passe!",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Une erreur est survenue lors de la modification du mot de passe",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
      setIsLoading(false);
    },
    [modalId, userId]
  );

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
  };
}

export default UseRequestPassword;
