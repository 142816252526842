import { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../redux/store";
import { Categorie } from "../../../../redux/types";
import { deleteCategorie, iconListener } from "../../../../utils/api/user.api";
import AddCategorieModal from "../../../modal/admin/AddCategorieModal";
import AddIconModal from "../../../modal/admin/AddIconModal";

export function CategoriesTab() {
  const categories = useAppSelector((s) =>
    s.categories.filter((f) => !f.userId)
  );
  const categoriesUsers = useAppSelector((s) =>
    s.categories.filter((f) => !!f.userId)
  );
  const [icons, setIcons] = useState<string[]>([]);

  useEffect(() => {
    const li = iconListener((icons) => setIcons(icons));
    return () => li();
  }, []);
  return (
    <div className="pt-5 categories-tab">
      <div className="categories-container mb-2">
        <div className="flex-sb align-items-center mb-2">
          <h3>Catégorie prédéfinie</h3>
          <div className="content-btn-add-theme">
            <button
              className="btn btn-add-theme"
              data-bs-toggle="modal"
              data-bs-target="#AddCategorieModal"
              id="btnAddCat"
            >
              <IoMdAdd />
              <span className="ps-2">Ajouter une catégorie</span>
            </button>
          </div>
        </div>
        <div className="categories-lists d-flex gap-2 flex-wrap">
          {categories.map((item) => (
            <CategorieItem item={item} key={item.id} />
          ))}
        </div>
      </div>

      <div className="icones-container pt-2">
        <div className="flex-sb align-items-center mb-2">
          <h3>Icones</h3>
          <div className="content-btn-add-theme">
            <button
              className="btn btn-add-theme"
              data-bs-toggle="modal"
              data-bs-target="#AddIconModal"
              id="btnAddIcon"
            >
              <IoMdAdd />
              <span className="ps-2">importer des icones</span>
            </button>
          </div>
        </div>
        <div
          className="icons-lists d-flex  flex-wrap gap-3 px-4 py-2"
          style={{ minHeight: "349px", background: "#158A91" }}
        >
          {icons.map((item, i) => (
            <div key={i}>
              <img src={item} alt="icon" width="20px" height="20px" />
            </div>
          ))}
        </div>
      </div>
      <div className="categories-container mb-2 mt-5">
        <div className="flex-sb align-items-center mb-2">
          <h3>Catégories clients</h3>
        </div>
        <div className="categories-lists d-flex gap-2 flex-wrap">
          {categoriesUsers.map((item) => (
            <CategorieItem item={item} key={item.id} />
          ))}
        </div>
      </div>
      <div
        className="modal fade"
        id="AddCategorieModal"
        aria-labelledby="AddCategorieModalLabel"
        aria-hidden="true"
      >
        <AddCategorieModal modalId="AddCategorieModal" btnId="btnAddCat" />
      </div>
      <div
        className="modal fade"
        id="AddIconModal"
        aria-labelledby="AddIconModalLabel"
        aria-hidden="true"
      >
        <AddIconModal modalId="AddIconModal" btnId="btnAddIcon" icons={icons} />
      </div>
    </div>
  );
}
function CategorieItem({ item }: { item: Categorie }) {
  const modalId = `CategorieItem${item.id}`;
  const btnId = `CategorieItemBtn${item.id}`;
  const onDeleteCat = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet catégorie ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: "#02B4C0",
      confirmButtonColor: "#02B4C0",
      preConfirm: async () => {
        const res = await deleteCategorie(item.id);
        if (res === false) {
          Swal.fire({
            icon: "error",
            title: `Catégorie non supprimée il ya des factures associées`,
            iconColor: "#02B4C0",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: `Catégorie supprimée avec succès!`,
            iconColor: "#02B4C0",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };
  return (
    <>
      <div className="d-flex align-items-center cat-item gap-2">
        <div
          className="icon-container"
          data-bs-toggle="modal"
          data-bs-target={`#${modalId}`}
          id={btnId}
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            backgroundColor: item.color,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img src={item.image} alt="icon" width="12px" height="12px" />
        </div>
        <div>
          <h5>
            {item.name}
            <small style={{ fontSize: 10 }}>
              {item.compte ? `(${item.compte})` : ""}
            </small>
          </h5>
        </div>

        <button className="btn-delete-cat m-0">
          <MdDelete onClick={() => onDeleteCat()} />
        </button>
      </div>
      <div
        className="modal fade"
        id={modalId}
        aria-labelledby="AddCategorieModalLabel"
        aria-hidden="true"
      >
        <AddCategorieModal item={item} modalId={modalId} btnId={btnId} />
      </div>
    </>
  );
}
