import { useState } from "react";
import { ImDownload3 } from "react-icons/im";
import Swal from "sweetalert2";
import Doc from "../../../assets/appImages/doc.png";
import Pdf from "../../../assets/icons/pdf.png";
import { updateFactStatutAsync } from "../../../utils/api/invoice.api";
import { getNameLabel, onHide } from "../../../utils/utils";

import { getDateFromString } from "../../../redux/slice/invoiceSlice";
import { formatAmout } from "../../../utils/formatter";
import CloseModalBtn from "../../shared/CloseModalBtn";
import EditFactureModal from "./EditFactureModal";

function DetailFactureComptabiliteModal({ facture, func, modalId, btnId }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeTraiter = async (x: boolean) => {
    setIsLoading(true);
    try {
      await updateFactStatutAsync(facture?.uid, { traiter: x });

      setIsLoading(false);
      Swal.fire({
        icon: "success",
        title: "Modification réussie!",
        showConfirmButton: false,
        iconColor: "#02B4C0",
        timer: 2000,
      }).then(async () => {
        await func();
        onHide(modalId, btnId);
        setIsLoading(false);
      });
    } catch (ex) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Une erreur est survenue!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <div>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content py-2">
          <div className="modal-header d-flex justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <h1 className="admin-modal-title text-start">
                Détails de la facture
              </h1>
              {facture?.traiter ? (
                <div className="statut-facture-valide">Validé</div>
              ) : (
                <div className="statut-facture-a-traiter">A traiter</div>
              )}
            </div>
            <CloseModalBtn />
          </div>
          <div className="container-form">
            <div className="row py-4">
              <div className="col-md-7">
                <div className="container-info-facture-comptabilite">
                  <div className="content-info-facture-comptabilite-item">
                    <div className="content-info-facture-comptabilite-libelle">
                      <p className="info-facture-comptabilite-libelle">
                        {getNameLabel(facture?.type_depense)}
                      </p>
                    </div>
                    <div className="content-info-facture-comptabilite-value">
                      <p className="info-facture-comptabilite-value">
                        {facture?.commerce_name}
                      </p>
                    </div>
                  </div>
                  <div className="content-info-facture-comptabilite-item">
                    <div className="content-info-facture-comptabilite-libelle">
                      <p className="info-facture-comptabilite-libelle">
                        Montant
                      </p>
                    </div>
                    <div className="content-info-facture-comptabilite-value">
                      <p className="info-facture-comptabilite-value">
                        {formatAmout(facture?.montant_ht)} HT
                      </p>
                    </div>
                  </div>
                  <div className="content-info-facture-comptabilite-item">
                    <div className="content-info-facture-comptabilite-libelle">
                      <p className="info-facture-comptabilite-libelle">TVA</p>
                    </div>
                    <div className="content-info-facture-comptabilite-value">
                      <p className="info-facture-comptabilite-value">
                        {formatAmout(facture?.tva)}
                      </p>
                    </div>
                  </div>
                  <div className="content-info-facture-comptabilite-item">
                    <div className="content-info-facture-comptabilite-libelle">
                      <p className="info-facture-comptabilite-libelle">Date</p>
                    </div>
                    <div className="content-info-facture-comptabilite-value">
                      <p className="info-facture-comptabilite-value">
                        {getDateFromString(
                          facture?.date,
                          facture.created_at
                        ).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </div>
                  <div className="content-info-facture-comptabilite-item">
                    <div className="content-info-facture-comptabilite-libelle">
                      <p className="info-facture-comptabilite-libelle">
                        Type de dépense
                      </p>
                    </div>
                    <div className="content-info-facture-comptabilite-value">
                      <p className="info-facture-comptabilite-value">
                        {facture?.type_depense}
                      </p>
                    </div>
                  </div>
                  <div className="content-info-facture-comptabilite-item">
                    <div className="content-info-facture-comptabilite-libelle">
                      <p className="info-facture-comptabilite-libelle">
                        Moyen de paiement
                      </p>
                    </div>
                    <div className="content-info-facture-comptabilite-value">
                      <p className="info-facture-comptabilite-value">
                        {facture?.moyen_paiement}
                      </p>
                    </div>
                  </div>
                  <div className="content-info-facture-comptabilite-item">
                    <div className="content-info-facture-comptabilite-libelle">
                      <p className="info-facture-comptabilite-libelle">
                        Catégorie
                      </p>
                    </div>
                    <div className="content-info-facture-comptabilite-value">
                      <p className="info-facture-comptabilite-value">
                        {facture?.cat}
                      </p>
                    </div>
                  </div>
                  <div className="content-info-facture-comptabilite-item">
                    <div className="content-info-facture-comptabilite-libelle">
                      <p className="info-facture-comptabilite-libelle">
                        Journal
                      </p>
                    </div>
                    <div className="content-info-facture-comptabilite-value">
                      <p className="info-facture-comptabilite-value">
                        {facture?.journal}
                      </p>
                    </div>
                  </div>
                  <div className="content-info-facture-comptabilite-item">
                    <div className="content-info-facture-comptabilite-libelle">
                      <p className="info-facture-comptabilite-libelle">
                        Nemero Comptable
                      </p>
                    </div>
                    <div className="content-info-facture-comptabilite-value">
                      <p className="info-facture-comptabilite-value">
                        {facture?.number_comptable}
                      </p>
                    </div>
                  </div>
                  <div className="content-info-facture-comptabilite-item content-montant-total-facture-modal m-2">
                    <div className="content-info-facture-comptabilite-libelle">
                      <p className="info-facture-comptabilite-libelle-montant">
                        Montant
                      </p>
                    </div>
                    <div className="content-info-facture-comptabilite-value">
                      <p className="info-facture-comptabilite-value-montant">
                        {formatAmout(facture?.montant_ttc)} TTC
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="content-document-facture">
                  <div className="d-flex gap-3">
                    <div className="content-doc-facture-item">
                      <img src={Pdf} alt="doc" className="img-doc-facture" />
                    </div>
                    <div className="content-doc-facture-item">
                      <img src={Doc} alt="doc" className="img-doc-facture" />
                    </div>
                  </div>
                  <div className="containet-btn-download-doc-facture mt-3">
                    <a
                      href={facture?.pdf}
                      title="download"
                      download
                      target="_blank"
                      className="btn btn-download-doc-facture"
                      rel="noreferrer"
                    >
                      <ImDownload3 />
                      <span className="ps-2">Télécharger</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-btn-modal row mt-4 px-1">
              <div className="col-md-4 offset-md-4">
                <button
                  className="btn auth-submit-btn"
                  data-tooltip-content="Modifier"
                  data-bs-target={`#EditFactureModal${facture?.uid}`}
                  data-bs-toggle={`modal`}
                  id={`bntEditFactureModal${facture.uid}`}
                >
                  Modifier
                </button>
              </div>
              <div className="col-md-4">
                <button
                  className={
                    facture.traiter == true
                      ? "btn auth-submit-btn-red"
                      : "btn auth-submit-btn"
                  }
                  onClick={() =>
                    handleChangeTraiter(facture.traiter ? false : true)
                  }
                  disabled={isLoading}
                >
                  {!isLoading ? (
                    facture.traiter ? (
                      "Marquer comme à traiter"
                    ) : (
                      "Marquer comme traité"
                    )
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>Enregistrement...</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={`EditFactureModal${facture.uid}`}
        aria-labelledby="EditFactureModalLabel"
        aria-hidden="true"
      >
        <EditFactureModal
          facture={facture}
          func={func}
          invoiceId={facture.uid}
          modalId={`EditFactureModal${facture.uid}`}
          btnId={`bntEditFactureModal${facture.uid}`}
        />
      </div>
    </div>
  );
}

export default DetailFactureComptabiliteModal;
