/* eslint-disable @typescript-eslint/no-use-before-define */
import moment from "moment";
import { FiArrowDown } from "react-icons/fi";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../redux/store";
import { MessageContact as MessageContactType } from "../../../../redux/types";
import { updateMessageContact } from "../../../../utils/api/user.api";
import { onHide } from "../../../../utils/utils";
import DetailsMessageModal from "../../../modal/admin/DetailMessageModal";
import "./MessageContact.css";
const MessageContact = () => {
  const messages = useAppSelector((s) => s.messages_contact);

  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
        <div className="admin-page-title-header-container flex-sb align-items-center">
          <div className="bubble-zone-dash-locataire-requetes-header">
            <h4 className="bubble-zone-dash-header-titre">Messages contact</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mt-0">
          <div className="">
            {messages.map((item) => (
              <MessageContactItem item={item} key={item.id} />
            ))}
            {messages.length === 0 && <p>Pas de message</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageContact;

function MessageContactItem({ item }: { item: MessageContactType }) {
  const modalId = `MessageContactItem${item.id}`;
  const buttonId = `MessageContactItemButton${item.id}`;
  const collapseId = `MessageContactCollapse${item.id}`;

  const onChangeStatus = () => {
    Swal.fire({
      title: "Changer le statut",
      input: "select",
      inputValue: item.status ?? "Nouveau",
      inputOptions: {
        Nouveau: "Nouveau",
        Traité: "Traité",
        "Non traité": "Non traité",
      },
      inputPlaceholder: "Statut",
      showCancelButton: true,
      iconColor: "#02B4C0",
      showLoaderOnConfirm: true,
      preConfirm: async (value) => {
        if (value) {
          await updateMessageContact(item.id, { status: value });
        }
      },
    });
  };
  return (
    <>
      <div className="mb-3">
        <div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
          <div className="row w-100 align-items-center">
            <div className="col-md-7">
              <div className="d-flex align-items-start gap-2">
                <div className="message-item-without-input-container">
                  <div
                    className="admin-message-contact-card-description d-flex btn p-0"
                    style={{
                      textOverflow: "ellipsis",
                    }}
                    data-bs-toggle="modal"
                    id={buttonId}
                    data-bs-target={`#${modalId}`}
                    role="button"
                  >
                    {item.sujet ?? item.message}
                  </div>
                  <p className="admin-message-contact-card-frequence fz-14 mb-0">
                    {moment(item.created_at).format("DD/MM/YYYY [à] HH:mm")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <button
                onClick={() => onChangeStatus()}
                className={`btn message-btn ${
                  !item.status || item.status === "Nouveau"
                    ? "bg-status"
                    : item.status === "Traité"
                    ? "bg-status-success"
                    : ""
                }`}
                aria-expanded="false"
              >
                {item.status ?? "Nouveaau"}
              </button>
            </div>
            <div className="col-md-3 flex-l">
              <p className="admin-message-contact-card-description mb-0">
                {item.name}
              </p>
            </div>
          </div>
          <button
            className="no-style-btn accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${collapseId}`}
            aria-expanded="false"
            aria-controls="responseContact"
          >
            <FiArrowDown />
          </button>
        </div>
        <div className="collapse" id={collapseId}>
          <div className="card card-body admin-content-message mb-1">
            {item.message}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={modalId}
        aria-labelledby="DetailsMessageModalLabel"
        aria-hidden="true"
      >
        <DetailsMessageModal
          item={item}
          onHide={() => onHide(modalId, buttonId)}
        />
      </div>
    </>
  );
}
