import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { onSetUserInfos } from "../../../../../redux/slice/user.slice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { UserInfos } from "../../../../../redux/types";
import { updateUserEmail } from "../../../../../utils/api/auth.api";
import {
  updateUserAsync,
  updateUsersEmailComptable,
  userListener,
} from "../../../../../utils/api/user.api";
import { cleannerError } from "../../../../../utils/utils";

function UseEditUser() {
  const validationSchema = yup.object().shape({
    email: yup.string().email().required().label("L'email"),
    prenom: yup.string().required().label("Le prénom"),
    nom: yup.string().required().label("Le nom"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm<Partial<UserInfos>>({
    resolver: yupResolver(validationSchema),
  });
  const { userInfos, user } = useAppSelector((s) => s.auth);

  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userInfos) {
      setValue("prenom", userInfos?.prenom);
      setValue("nom", userInfos?.nom);
      setValue("email", userInfos?.email);
    }
    return () => {};
  }, [userInfos]);
  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);

  const onSubmit = async (data: Partial<UserInfos>) => {
   
    setIsLoading(true);
    try {
      if (data.email && data.email !== userInfos?.email) {
        await updateUserEmail(userInfos?.uid, data.email);
        const res = await updateUsersEmailComptable(
          userInfos?.email as string,
          data.email
        );
        
      }
      await updateUserAsync(userInfos?.uid, data);
      if (user) {
        userListener(user, (userInfos) => {
          dispatch(onSetUserInfos(userInfos));
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Modification réussie!",
            showConfirmButton: false,
            iconColor: "#02B4C0",
            timer: 2000,
          });
        });
      } else {
        setIsLoading(false);
      }
    } catch (ex: any) {
      setIsLoading(false);
      if (ex.code === "auth/requires-recent-login") {
        Swal.fire({
          icon: "error",
          title:
            "Veuillez vous déconnecter et vous reconnecter pour modifier email!",
          showConfirmButton: false,
          timer: 3000,
        });
      } else if (
        [
          "auth/credential-already-in-use",
          "auth/email-already-in-use",
          "auth/account-exists-with-different-credential",
          "auth/provider-already-linked",
        ].includes(ex.code)
      ) {
        Swal.fire({
          icon: "error",
          title: "Cet e-mail existe déjà",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Une erreur est survenue!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
  };
}

export default UseEditUser;
