import React from "react";
import { BiCategory } from "react-icons/bi";
import { BsChatDots } from "react-icons/bs";
import { IoPersonOutline, IoSettingsOutline } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { TiDocumentText } from "react-icons/ti";
import { NavLink } from "react-router-dom";
import Logo2 from "../../../../assets/appImages/Fast_Facture.png";
import Logo from "../../../../assets/appImages/logo-init.png";
import { onLogout } from "../../../../redux/slice/user.slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { logoutAsync } from "../../../../utils/api/auth.api";
import { hasPermission } from "../../../../utils/utils";

const SidebaragenceContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { userInfos } = useAppSelector((s) => s?.auth);
  const Logout = () => {
    dispatch(onLogout());
    logoutAsync();
  };
  // const base = userInfos?.is_superadmin ? "/super-admin" : "/admin";
  const base = "/admin";
  return (
    <div className="hoverable" id="scrollbar">
      <div className="content">
        <div className="container-user-connect-info pb-3">
          <div className="container-img-user-connect-nav-menu">
            <img
              className="img-user-connect-navmenu"
              src={Logo}
              alt="user connect"
            />
          </div>
          <div className="container-info-user-connect-navmenu ps-2">
            <div className="ligne-logo"></div>
            <div className="container-other-text-logo ms-2">
              <img className="img-others-text" src={Logo2} alt="user connect" />
            </div>
          </div>
        </div>
        <ul className="nav nav-items-custom flex-column">
          <li
            className="nav-item nav-item-vertical-custom"
            hidden={!hasPermission(userInfos, "dashboard")}
          >
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to={`${base}/dashboard`}
            >
              <BiCategory />
              <span className="hiddenable-navlink-label">Dashboard</span>
            </NavLink>
          </li>
          <li
            className="nav-item nav-item-vertical-custom position-relative"
            hidden={!hasPermission(userInfos, "utilisateurs")}
          >
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to={`${base}/utilisateurs`}
            >
              <IoPersonOutline />
              <span className="hiddenable-navlink-label">Clients </span>
            </NavLink>
          </li>
          <li
            className="nav-item nav-item-vertical-custom"
            hidden={!hasPermission(userInfos, "factures")}
          >
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to={`${base}/factures`}
            >
              <TiDocumentText />
              <span className="hiddenable-navlink-label">Factures</span>
            </NavLink>
          </li>
          <li
            hidden={!hasPermission(userInfos, "utilisateurs")}
            className="nav-item nav-item-vertical-custom"
          >
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to={`${base}/comptables`}
            >
              <IoPersonOutline />
              <span className="hiddenable-navlink-label">Comptables</span>
            </NavLink>
          </li>
          <li
            className="nav-item nav-item-vertical-custom"
            hidden={!hasPermission(userInfos, "messages")}
          >
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to={`${base}/messagerie`}
            >
              <BsChatDots />
              <span className="hiddenable-navlink-label">Messages</span>
            </NavLink>
          </li>
          <li
            className="nav-item nav-item-vertical-custom"
            hidden={!hasPermission(userInfos, "parametres")}
          >
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to={`${base}/parametres`}
            >
              <IoSettingsOutline />
              <span className="hiddenable-navlink-label">Parametres</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to={`${base}/profil`}
            >
              <IoPersonOutline />
              <span className="hiddenable-navlink-label">Profil</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <button
              className="btn nav-link nav-link-vertival-custom"
              onClick={() => Logout()}
            >
              <MdLogout />
              <span className="hiddenable-navlink-label">Deconnexion</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SidebaragenceContent;
