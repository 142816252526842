import { useEffect, useRef, useState } from "react";

export function HtmlInput({
  onChange,
  data = "",
  name = "description",
}: {
  onChange: (value: string) => any;
  name: string;
  data?: string;
}) {
  const [uniqueId] = useState(`${name}_${new Date().getTime()}`);
  const editor = useRef();
  useEffect(() => {
    // @ts-ignore
    if (!editor.current && window.ClassicEditor?.create) {
      // @ts-ignore
      editor.current = { setData: () => null, getData: () => null };
      // @ts-ignore
      window.ClassicEditor.create(document.querySelector(`#${uniqueId}`), {
        initialData: data,
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            "imageUpload",
            "blockQuote",
            "mediaEmbed",
            "undo",
            "redo",
          ],
        },
        language: "en",
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
          ],
        },
        licenseKey: "",
      })
        .then((ed) => {
          const modelDocument = ed.model.document;
          modelDocument.on("change:data", (event) => {
            const data = ed.getData();
            onChange(data);
          });
          editor.current = ed;
        })
        .catch((err) => {
          console.error(err.stack);
        });
    }
  }, [editor.current]);

  useEffect(() => {
    if (editor.current && data) {
      // @ts-ignore
      const ed = editor.current.getData();
      if (!ed && data) {
        // @ts-ignore
        editor.current.setData(data);
      }
    }
  }, [editor.current, data]);

  return <div id={uniqueId} />;
}
