import React, { useEffect } from "react";
import { useAppSelector } from "../../../../redux/store";
import { UserInfos } from "../../../../redux/types";
import { isAdminUser } from "../../../../utils/utils";
import ListeUsersTable from "../../../tables/admin/ListeUsersTable/ListeUsersTable";
import "./ListeUsers.css";

const ListeComptables = () => {
  const users = useAppSelector((s) => s.users);
  const [data, setData] = React.useState<UserInfos[]>([]);
  useEffect(() => {
    setData(
      users.filter((f) => {
        return !isAdminUser(f) && f.is_comptable;
      })
    );
  }, [users]);
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="admin-page-title-header-container flex-sb align-items-center">
          <div className="fast-facture-dash-locataire-requetes-header">
            <h4 className="fast-facture-dash-header-titre">Comptables</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="bg-white p-3 min-h-80">
            <ListeUsersTable is_comptable data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeComptables;
