import React from 'react'
import Profil1 from '../../../../../assets/appImages/user.png'

function TabsUsersEntreprise() {
  return (
        <div className="bg-white p-3">
           <div className='content-info-user-entreprise'>
                <div className='d-flex align-items-center gap-2'>
                    <div className='content-img-profil-user-entreprise'>
                        <img src={Profil1} alt='profil' className='img-pp-user-entreprise' />
                    </div>
                    <div className='content-text-info-user-entreprise'>
                        <p className='name-user-profil'>Jane Cooper</p>
                        <p className='text-date-user-profil'>il ya 10 h</p>
                    </div>
                </div>
                <div className='content-btn-voir-profil'>
                    <button className='btn btn-voir-profil'>Voir profil</button>
                </div>
           </div>
           <div className='content-info-user-entreprise'>
                <div className='d-flex align-items-center gap-2'>
                    <div className='content-img-profil-user-entreprise'>
                        <img src={Profil1} alt='profil' className='img-pp-user-entreprise' />
                    </div>
                    <div className='content-text-info-user-entreprise'>
                        <p className='name-user-profil'>Jane Cooper</p>
                        <p className='text-date-user-profil'>il ya 10 h</p>
                    </div>
                </div>
                <div className='content-btn-voir-profil'>
                    <button className='btn btn-voir-profil'>Voir profil</button>
                </div>
           </div>
           <div className='content-info-user-entreprise'>
                <div className='d-flex align-items-center gap-2'>
                    <div className='content-img-profil-user-entreprise'>
                        <img src={Profil1} alt='profil' className='img-pp-user-entreprise' />
                    </div>
                    <div className='content-text-info-user-entreprise'>
                        <p className='name-user-profil'>Jane Cooper</p>
                        <p className='text-date-user-profil'>il ya 10 h</p>
                    </div>
                </div>
                <div className='content-btn-voir-profil'>
                    <button className='btn btn-voir-profil'>Voir profil</button>
                </div>
           </div>
           <div className='content-info-user-entreprise'>
                <div className='d-flex align-items-center gap-2'>
                    <div className='content-img-profil-user-entreprise'>
                        <img src={Profil1} alt='profil' className='img-pp-user-entreprise' />
                    </div>
                    <div className='content-text-info-user-entreprise'>
                        <p className='name-user-profil'>Jane Cooper</p>
                        <p className='text-date-user-profil'>il ya 10 h</p>
                    </div>
                </div>
                <div className='content-btn-voir-profil'>
                    <button className='btn btn-voir-profil'>Voir profil</button>
                </div>
           </div>
           <div className='content-info-user-entreprise'>
                <div className='d-flex align-items-center gap-2'>
                    <div className='content-img-profil-user-entreprise'>
                        <img src={Profil1} alt='profil' className='img-pp-user-entreprise' />
                    </div>
                    <div className='content-text-info-user-entreprise'>
                        <p className='name-user-profil'>Jane Cooper</p>
                        <p className='text-date-user-profil'>il ya 10 h</p>
                    </div>
                </div>
                <div className='content-btn-voir-profil'>
                    <button className='btn btn-voir-profil'>Voir profil</button>
                </div>
           </div>
           <div className='content-info-user-entreprise'>
                <div className='d-flex align-items-center gap-2'>
                    <div className='content-img-profil-user-entreprise'>
                        <img src={Profil1} alt='profil' className='img-pp-user-entreprise' />
                    </div>
                    <div className='content-text-info-user-entreprise'>
                        <p className='name-user-profil'>Jane Cooper</p>
                        <p className='text-date-user-profil'>il ya 10 h</p>
                    </div>
                </div>
                <div className='content-btn-voir-profil'>
                    <button className='btn btn-voir-profil'>Voir profil</button>
                </div>
           </div>
           <div className='mt-4 flex-r content-see-more-users-entreprise'>
                <button className='btn btn-see-more'>Voir plus</button>
           </div>
        </div>
    )
}

export default TabsUsersEntreprise