import { useAppSelector } from "../../../../redux/store";
import { isAdminUser } from "../../../../utils/utils";
import SideBarComptableContent from "./SideBarComptableContent";
import "./SidebarAdmin.css";
import SidebarAdminContent from "./SidebarAdminContent";

const SidebarAdmin = () => {
  const { userInfos } = useAppSelector((s) => s?.auth);
  const isAdmin = isAdminUser(userInfos);
  return (
    <div className="navbar-vertical-customisation no-view-mobile">
      {isAdmin && <SidebarAdminContent />}
      {!isAdmin && <SideBarComptableContent />}
    </div>
  );
};

export default SidebarAdmin;
