import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getDateFromString } from "../../../../redux/slice/invoiceSlice";
import { formatAmout } from "../../../../utils/formatter";
import { getNameLabel } from "../../../../utils/utils";

const FactureDetail = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { state } = location;

  useEffect(() => {
    if (!state?.uid) {
      setTimeout(() => {
        navigate(-1);
      }, 10);
    }
  }, [state]);

  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="admin-page-title-header-container">
          <div className="fast-facture-dash-locataire-requetes-header">
            <h4
              className="fast-facture-dash-header-titre linkable"
              onClick={() => navigate(-1)}
            >
              Facture&gt;{" "}
              <span style={{ fontSize: 16, fontWeight: 400 }}>
                {state?.userId?.prenom} {state?.userId?.nom}
              </span>
            </h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="bg-white p-3">
            <div className="flex-r">
              <p className="text-date-facture">
                Date :{" "}
                {getDateFromString(state?.date, state?.created_at).format(
                  "DD/MM/YYYY"
                )}
              </p>
            </div>
            <div className="container-info-detail-facture mb-4">
              <div className="content-text-detail-facture">
                <p className="title-info-detail-facture">Info pro</p>
              </div>
              <div className="flex-sb">
                <div className="content-info-detail-facture-libelle">
                  <p className="text-detail-facture-libelle">
                    {getNameLabel(state?.type_depense)}
                  </p>
                </div>
                <div className="content-info-detail-facture-value">
                  <p className="text-detail-facture-value">
                    {state?.commerce_name || "Non défini"}
                  </p>
                </div>
              </div>
              {/* <div className="flex-sb">
                <div className="content-info-detail-facture-libelle">
                  <p className="text-detail-facture-libelle">N de siret</p>
                </div>
                <div className="content-info-detail-facture-value">
                  <p className="text-detail-facture-value">0000000000000</p>
                </div>
              </div> */}
            </div>
            <div className="container-info-detail-facture mb-4">
              <div className="content-text-detail-facture">
                <p className="title-info-detail-facture">Info client</p>
              </div>
              <div className="flex-sb">
                <div className="content-info-detail-facture-libelle">
                  <p className="text-detail-facture-libelle">Nom</p>
                </div>
                <div className="content-info-detail-facture-value">
                  <p className="text-detail-facture-value">
                    {state?.userId?.prenom} {state?.userId?.nom}
                  </p>
                </div>
              </div>
              <div className="flex-sb">
                <div className="content-info-detail-facture-libelle">
                  <p className="text-detail-facture-libelle">Entreprise</p>
                </div>
                <div className="content-info-detail-facture-value">
                  <p className="text-detail-facture-value">
                    {state?.userId?.entreprise || "Non défini"}
                  </p>
                </div>
              </div>
            </div>
            <div className="container-info-detail-facture mb-4">
              <div className="content-text-detail-facture">
                <p className="title-info-detail-facture">
                  Montant de la commande
                </p>
              </div>
              <div className="flex-sb">
                <div className="content-info-detail-facture-libelle">
                  <p className="text-detail-facture-montant-libelle">
                    {formatAmout(state?.montant_ht)}HT
                  </p>
                </div>
                <div className="content-info-detail-facture-value">
                  <p className="text-detail-facture-value">
                    TVA {formatAmout(state?.tva)}
                  </p>
                </div>
              </div>
            </div>
            <div className="content-montant-total flex-r">
              <p className="text-montant-total-facture">
                Montant TTC{" "}
                <span
                  style={{ fontWeight: 700, fontSize: 22, paddingLeft: 10 }}
                >
                  {formatAmout(state?.montant_ttc)}{" "}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FactureDetail;
