import moment from "moment";
import React from "react";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Profil from "../../../../assets/appImages/avatar1.png";
import { useAppSelector } from "../../../../redux/store";
import {
  deleteNotification,
  getUserById,
  updateNotification,
} from "../../../../utils/api/user.api";
import "./HeaderAdmin.css";

const HeaderAdmin: React.FC = () => {
  const notificationsCount = useAppSelector(
    (s) => s.notifications.filter((f) => !f.read).length
  );
  var [showNotifications, setShowNotifications] = React.useState(false);
  const { userInfos } = useAppSelector((s) => s?.auth);

  var onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };
  let prefix = userInfos?.is_admin ? "/super-admin/profil" : "/admin/profil";
  if (userInfos?.is_comptable) {
    prefix = "/comptabilite/profil";
  }

  return (
    <div className="admin-header-component">
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering-admin">
          <div className="search-form-container"></div>

          <div className="header-others-items">
            <div className="container-menu-header-admin gap-3">
              <div className="sent-message-header-container position-relative">
                {!userInfos?.is_comptable && (
                  <NavLink
                    to={
                      userInfos?.is_admin
                        ? "/admin/parametres"
                        : "/super-admin/parametres"
                    }
                    className="btn no-link b-0 p-0 btn-header-param"
                  >
                    <IoSettingsOutline />
                  </NavLink>
                )}
              </div>
              <div
                className="notification-item-container linkable position-relative"
                onClick={() => onShowAndHideNotification()}
              >
                <div className="notification-item-content">
                  <IoIosNotificationsOutline size={32} />
                  {notificationsCount > 0 && (
                    <span className="notification-counter">
                      <span style={{ fontSize: 10, color: "#FFF" }}>
                        {notificationsCount}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="connected-user-container">
              <div className="connected-user-content ml-auto">
                <NavLink
                  className="dis-flex no-link btn-header-param"
                  to={prefix}
                >
                  <div className="user-acount-dashboard-header-display g-2">
                    <img
                      src={userInfos?.avatar || Profil}
                      className="user-avatar"
                      alt="User Avatar"
                    />
                    <span className="user-name-connect px-2 no-view-mobile">
                      {userInfos?.prenom}&nbsp;{userInfos?.nom}
                    </span>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="hiddenable-nav-mobile-container no-view-desktop">
          <div className="hiddenable-nav-mobile-content"></div>
        </div>
        {showNotifications && (
          <Notifications
            onHide={() => {
              setShowNotifications(false);
            }}
          />
        )}
      </nav>
    </div>
  );
};

export default HeaderAdmin;

function Notifications({ onHide }: { onHide: () => any }) {
  const navigate = useNavigate();
  const user = useAppSelector((s) => s.auth?.userInfos);
  const notifications = useAppSelector((s) => s.notifications);
  return (
    <div className={"header-hiddenable-notification-section"}>
      <ul className="header-hiddenable-notification-ul">
        {notifications.length === 0 && (
          <li className="header-hiddenable-notification-li">
            <div className="notification-item-link d-flex no-link">
              <div className="content-text-notif ps-2">
                <p className="text-notif mb-1">Pas de nouvel notification</p>
              </div>
            </div>
          </li>
        )}
        {notifications.map((n) => {
          const onClick = async () => {
            if (!n.read) {
              updateNotification(n.uid, { read: true }).catch((ex) => null);
            }
            const isAdmin = user?.is_admin || user?.is_superadmin;
            if (isAdmin) {
              navigate("/admin/dashboard");
            } else {
              if (n.invoiceId && n.from) {
                Swal.showLoading();
                try {
                  const user = await getUserById(n.from);
                  if (user) {
                    navigate(
                      `/comptabilite/utilisateur/${n.from}?invoiceId=${n.invoiceId}`,
                      { state: user }
                    );
                  } else {
                    navigate(`/comptabilite/utilisateurs`);
                  }
                } catch (ex) {
                  navigate(`/comptabilite/utilisateurs`);
                }
                Swal.hideLoading();
                Swal.close();
              } else {
                navigate(`/comptabilite/utilisateurs`);
              }
            }
            onHide();
          };
          const diff = moment().diff(moment(n.created_at), "days");
          console.log("diff", diff);
          if (diff > 5 && n.read) {
            deleteNotification(n.uid).catch((ex) => null);
          }
          return (
            <li key={n.uid} className="header-hiddenable-notification-li">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  onClick();
                }}
                className="notification-item-link d-flex no-link"
                style={{
                  backgroundColor: n.read ? "#FFF" : "#eff1f7",
                  padding: 5,
                }}
              >
                <div className="content-text-notif ps-2">
                  <p className="text-notif mb-1">
                    <b>{n.title}</b>: {n.content}
                  </p>
                  <p className="text-date-notif mb-0">
                    {moment(n.created_at).format("DD/MM/YYYY HH:mm")}
                  </p>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
