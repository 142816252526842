import Logo from "../assets/appImages/app-logo.png";
import { useAppSelector } from "../redux/store";
import "./auth/Login.css";

const Privacy = () => {
  const content = useAppSelector((s) => s.content);

  return (
    <div className="auth-component">
      <div className="fixed-login-page">
        <div className="auth-container">
          <div className="row">
            <div className="col-md-12 align-items-center d-flex justify-content-center auth-col auth-left-side-col">
              <div className="content-logo-app">
                <img src={Logo} alt="Logo" className="logo-app" />
              </div>
            </div>
            <div className="col-md-12 mx-4">
              <div className="auth-form-container">
                <h1 className="my-3">Politique de confidentialité</h1>
                <p dangerouslySetInnerHTML={{ __html: content.html }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
