import XLSX from "sheetjs-style";

function ExcelExport({
  getFileData,
  fileName,
}: {
  getFileData: () => any[];
  fileName: string;
}) {
  const fileExtension = ".xlsx";

  const exportToExcel = async (e) => {
    e?.preventDefault();
    var workbook = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(getFileData());
    XLSX.utils.book_append_sheet(workbook, ws, "Results");
    XLSX.writeFile(workbook, fileName + fileExtension, { type: "file" });
  };
  return (
    <div className="content-btn-see-more">
      <button
        className="btn btn-export-info-tabs"
        onClick={(e) => exportToExcel(e)}
      >
        Exporter
      </button>
    </div>
  );
}

export default ExcelExport;
