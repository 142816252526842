import React from "react";
import { FiMail } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/appImages/app-logo.png";
import ErrorMessage from "../common/ErrorMessage";
import UseResetPassword from "./Form/UseResetPassword";

const ForgetPassword = () => {
  const { register, errors, onSubmit, isLoading } = UseResetPassword();
  return (
    <div className="auth-component">
      <div className="fixed-login-page">
        <div className="auth-container">
          <div className="auth-row row">
            <div className="col-md-6 auth-col auth-left-side-col">
              <div className="auth-left-side-container">
                <div className="content-logo-app">
                  <img src={Logo} alt="Logo" className="logo-app" />
                </div>
              </div>
            </div>
            <div className="col-md-6 auth-col auth-right-side-col">
              <div className="auth-right-side-container">
                <div className="auth-form-container">
                  <h1 className="auth-form-title">
                    Récupération du Mot de Passe
                  </h1>
                  <div className="p-4">
                    <h4 className="auth-form-message">
                      Une demande de réinitialisation de mot de passe va être
                      envoyé par e-mail
                    </h4>
                  </div>
                  <div className="mt-4">
                    <form id="auth-form" onSubmit={onSubmit}>
                      <div className="row auth-form-row">
                        <div className="col-md-12 auth-input-col mb-3">
                          <div className="input-group auth-form-group auth-form-group-login">
                            <span
                              className="input-group-text input-group-text-custom"
                              id="basic-addon1"
                            >
                              <FiMail />
                            </span>
                            <input
                              type={`email`}
                              className="form-control auth-form-control"
                              id="email-or-username"
                              placeholder="Identifiant"
                              {...register("email")}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                          <ErrorMessage message={errors?.email?.message} />
                        </div>
                        <div className="col-md-12 auth-submit-btn-container">
                          <button
                            type="submit"
                            className="btn auth-submit-btn trans-0-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                  role="status"
                                ></span>
                                <span>Reinitialisation...</span>
                              </>
                            ) : (
                              "Reinitialiser le mot de passe"
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
