import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import $ from "jquery";
import { useEffect, useState } from "react";
import { useAppSelector } from "../redux/store";
import { Categorie, UserInfos } from "../redux/types";

const storage = getStorage();
export function cleannerError(
  errors: any,
  cleanner: any,
  timeOut: number = 3000
) {
  if (errors) {
    setTimeout(
      () => Object.entries(errors).map(([key]: any) => cleanner(key)),
      timeOut
    );
  }
}

async function upload(
  file: File,
  directory: string,
  path: string,
  defaultExtension?: string
) {
  const parts = file.name.split(".");
  const extension = parts[parts.length - 1];
  const reference = ref(
    storage,
    `${directory}/${path}.${extension ?? defaultExtension}`
  );
  await uploadBytes(reference, file);
  return getDownloadURL(reference);
}

export async function uploadUserAvatarAsync(file: File, uid?: string) {
  if (!uid) return;
  return upload(file, "avatars", uid);
}

export async function uploadCatImage(image: File) {
  return upload(image, "categories", new Date().getTime().toString());
}

export async function uploadIcons(images: File[]) {
  return Promise.all(
    images.map((image) =>
      upload(image, "icons", new Date().getTime().toString())
    )
  );
}

export const onHide = (modalId: string, btnId: string) => {
  document.getElementById(modalId)?.classList.remove("show");
  $(".modal-backdrop").remove();
  $(`#${modalId}`).hide();
  $(`#${btnId}`).click();
  $(`body`).css({ overflow: "scroll" });
};

export function useActiveTab() {
  const [active, setActive] = useState<number>(0);

  const handleActiveTab = (index: number) => {
    setActive(index);
  };

  return {
    active,
    handleActiveTab,
  };
}

export function getNameLabel(depsType?: string) {
  if (depsType === "Émise") return "Nom du client";
  return "Nom du commerce";
}

export const useUserCategories = (userId: string) => {
  const [cats, setCats] = useState<Categorie[]>([]);

  const categories = useAppSelector((s) => s.categories);
  useEffect(() => {
    setCats(categories.filter((f) => !f.userId || f.userId === userId));
  }, [categories]);

  return cats;
};

export function isAdminUser(user?: UserInfos | null) {
  if (!user) return false;
  const isAdmin = user.is_admin || user.is_superadmin;

  return !!isAdmin;
}

export function hasPermission(user?: UserInfos | null, permission?: string) {
  if (!user) return false;
  if (user.is_superadmin) return true;
  return (
    user.permissions && permission && user.permissions.includes(permission)
  );
}

const CURRENCY_FORMATTER = new Intl.NumberFormat("fr-FR", {
	currency: "XOF",
	style: "currency",
});

export function formatCurrency(number: number | any) {
	return CURRENCY_FORMATTER.format(number);
}

export const formatNumber = (num:any) => {
  // console.log(num)
  var p = parseFloat(num).toFixed(2).split(".");
  return (
      p[0]
          .split("")
          .reverse()
          .reduce(function (acc, num, i, orig) {
              return num === "-"
                  ? acc
                  : num + (i && !(i % 3) ? "." : "") + acc;
          }, "")
  );
};
