import { Categorie } from "../../../redux/types";
import ErrorMessage from "../../common/ErrorMessage";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { UseCrudCategorie } from "../../TableauDebord/Admin/ParametreAdmin/request/UseCrudAdmin";

const AddCategorieModal = ({
  modalId,
  btnId,
  item,
}: {
  modalId: string;
  btnId: string;
  item?: Categorie;
}) => {
  const { register, errors, onSubmit, isLoading, handleReset, setImage } =
    UseCrudCategorie(modalId, btnId, item);

  return (
    <div className="modal-dialog">
      <div className="modal-content text-start">
        <div className="modal-header py-2 flex-m">
          <h5 className="admin-modal-title text-start">
            {item ? "Modifier" : "Ajouter"} une catégorie
          </h5>
          <CloseModalBtn onClick={() => handleReset()} />
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-12 mb-2">
                  <input
                    type="text"
                    placeholder="Nom catégorie"
                    className="form-control form-control-modal-custom"
                    {...register("name")}
                  />
                  <ErrorMessage message={errors?.name?.message} />
                </div>
                <div className="col-12 mb-2">
                  <input
                    type="text"
                    placeholder="Numéro compte"
                    className="form-control form-control-modal-custom"
                    {...register("compte")}
                  />
                </div>
                <div className="col-12 mb-2">
                  <input
                    type="color"
                    placeholder="Couleur"
                    className="form-control form-control-modal-custom"
                    {...register("color")}
                  />
                  <ErrorMessage message={errors?.color?.message} />
                </div>
                <div className="col-12 mb-3">
                  <label htmlFor="image" className="input-file-control">
                    <span>Importer une icône (PNG)</span>
                    <input
                      type="file"
                      placeholder="Importer une icône (PNG)"
                      accept="image/png"
                      className="form-control form-control-modal-custom"
                      id="image"
                      onChange={(e) => {
                        const files = e.target.files;
                        if (files && files.length > 0) {
                          setImage(files[0]);
                        }
                      }}
                    />
                  </label>
                  <ErrorMessage message={errors?.image?.message} />
                </div>
              </div>
              <div className="container-btn-modal row mt-4">
                <div className="col-md-4">
                  <button
                    className="btn auth-submit-btn"
                    type="submit"
                    disabled={isLoading}
                  >
                    {!isLoading ? (
                      item ? (
                        "Modifier"
                      ) : (
                        "Envoyer"
                      )
                    ) : (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>Enregistrement...</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategorieModal;
