import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { LoginFormData } from "../../../redux/types";
import { loginAsync } from "../../../utils/api/auth.api";
import { cleannerError } from "../../../utils/utils";

function UseLoginForm() {
  const validationSchema = yup.object().shape({
    email: yup.string().email().required().label("Email"),
    password: yup.string().required().label("Mot de passe"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm<LoginFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);

  const onSubmit = async (data: LoginFormData) => {
    
    setIsLoading(true);
    try {
      await loginAsync(data.email, data.password);
    } catch (ex) {
      setError("email", { message: "Identifiants incorrects" });
      
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  };

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
  };
}

export default UseLoginForm;
