import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { ResetFormData } from "../../../redux/types";
import { sendPasswordResetLinkAsync } from "../../../utils/api/auth.api";
import { cleannerError } from "../../../utils/utils";

function UseResetPassword() {
  const validationSchema = yup.object().shape({
    email: yup.string().email().required().label("Email"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm<ResetFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);

  const onSubmit = async (data: ResetFormData) => {
    setIsLoading(true);
    try {
      await sendPasswordResetLinkAsync(data.email);
      Swal.fire({
        icon: "success",
        title:
          "Un lien pour réinitialiser votre mot de passe a été envoyé par mail, veuillez vérifier votre boîte de réception!",
        showConfirmButton: false,
        iconColor: "#02B4C0",
        timer: 3000,
      });

      setTimeout(() => {
        navigate("/", { replace: true });
      }, 1000);
    } catch (err: any) {
        console.log(err)
      setError("email", {
        message: "L'utilisateur avec cet e-mail n'a pas été trouvé",
      });
    }
    setIsLoading(false);
  };

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
  };
}

export default UseResetPassword;
