import React from 'react'

function TabsInfoEntreprise() {
  return (
    <div className="bg-white p-3 filter-and-table-container">
        <div className='row row-info-perso-user'>
            <div className='col-md-6'>
                <div className='content-text-info-user'>
                    <p className='text-info-user-libelle'>Nom </p>
                </div>
            </div>
            <div className='col-md-6'>
                <div className='content-text-info-user'>
                    <p className='text-info-user-value'>Volkeno </p>
                </div>
            </div>
        </div>
        <div className='row row-info-perso-user'>
            <div className='col-md-6'>
                <div className='content-text-info-user'>
                    <p className='text-info-user-libelle'>Téléphone </p>
                </div>
            </div>
            <div className='col-md-6'>
                <div className='content-text-info-user'>
                    <p className='text-info-user-value'>77 000 00 00 </p>
                </div>
            </div>
        </div>
        <div className='row row-info-perso-user'>
            <div className='col-md-6'>
                <div className='content-text-info-user'>
                    <p className='text-info-user-libelle'>Adresse </p>
                </div>
            </div>
            <div className='col-md-6'>
                <div className='content-text-info-user'>
                    <p className='text-info-user-value'>Monaco (France)</p>
                </div>
            </div>
        </div>
        <div className='row row-info-perso-user'>
            <div className='col-md-6'>
                <div className='content-text-info-user'>
                    <p className='text-info-user-libelle'>No siret</p>
                </div>
            </div>
            <div className='col-md-6'>
                <div className='content-text-info-user'>
                    <p className='text-info-user-value'>00000</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TabsInfoEntreprise