import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { InvoiceData } from "../types";

const initialState: InvoiceData[] = [];

export const InvoiceSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    onInvoices: (state, action: PayloadAction<InvoiceData[]>) => {
      const newState = [...state];
      for (const i of action.payload) {
        const index = newState.findIndex((o) => o.id === i.id);
        if (index === -1) {
          newState.push(i);
        } else {
          newState[index] = i;
        }
      }
      return newState.sort((a, b) => getFactureDate(b) - getFactureDate(a));
    },
  },
});

export const { onInvoices } = InvoiceSlice.actions;

export const getFactureDate = (b: InvoiceData) => {
  return getDateFromString(b.date, b.created_at)?.toDate()?.getTime() ?? 0;
};

export function getDateFromString(date?: string, created_at?: any) {
  if (!date) {
    return moment(created_at);
  }
  let d = moment(date, "DD/MM/YYYY");
  if (!d.isValid()) {
    d = moment(date, "DD-MM-YYYY");
  }
  if (!d.isValid()) {
    d = moment(date);
  }
  if (d.isValid()) {
    return d;
  }
  return moment(created_at);
}
