import { useState } from "react";
import { useAddIcon } from "../../../utils/api/user.api";
import CloseModalBtn from "../../shared/CloseModalBtn";
const AddIconModal = ({
  modalId,
  btnId,
  icons,
}: {
  modalId: string;
  btnId: string;
  icons: string[];
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const { addCat, loading } = useAddIcon(modalId, btnId);
  const handleReset = (event?:any) => {
    setFiles([]);
    if(event)
    {
      event.preventDefault();
      event.target.reset();
    }
    else{
      const form = document.getElementById("icon") as HTMLFormElement;
      if (form) {
        form.value = '';
      }
    }
  };

  
  const onSubmit = async () => {
    if (files.length > 0) {
      const res = await addCat(icons, files);
      if (res) {
        handleReset();
      }
    }
  };

  return (
    <div className="modal-dialog">
      <div className="modal-content text-start">
        <div className="modal-header py-2 flex-m">
          <h5 className="admin-modal-title text-start">Ajouter des icones</h5>
          <CloseModalBtn onClick={() => handleReset()} />
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              id="iconForm"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="row">
                <div className="col-12 mb-3">
                  <label htmlFor="icon" className="input-file-control">
                    <span>
                      {files.length === 0
                        ? "Importer des icônes (PNG)"
                        : `${files.length} icônes sélectionnées`}
                    </span>
                    <input
                      type="file"
                      placeholder="Importer des icônes (PNG)"
                      className="form-control form-control-modal-custom"
                      id="icon"
                      multiple
                      accept="image/png"
                      onChange={(e) => {
                        const files = e.target.files;
                        
                        if (files && files.length > 0) {
                          setFiles(Array.from(files));
                        } else {
                          handleReset();
                        }
                      }}
                    />
                  </label>
                </div>
              </div>
              <div className="container-btn-modal row mt-4">
                <div className="col-md-4">
                  <button
                    className="btn auth-submit-btn"
                    type="submit"
                    disabled={loading}
                  >
                    {!loading ? (
                      "Envoyer"
                    ) : (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>Enregistrement...</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddIconModal;
