import { User } from "firebase/auth";
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { InvoiceData, UserInfos } from "../../redux/types";

const firestore = getFirestore();
const functions = getFunctions();

export function invoiceListener(user: User, cb: (data: InvoiceData[]) => any) {
  const q = query(
    collection(firestore, "invoices"),
    where("userId", "==", user.uid)
  );
  return onSnapshot(q, (querySnapshot) => {
    const invoices: InvoiceData[] = [];
    querySnapshot.docChanges().forEach((change) => {
      const dta: any = {
        ...change.doc.data(),
        id: change.doc.id,
        created_at:
          change.doc.data().created_at?.toDate()?.getTime() ||
          new Date().getTime(),
      };
      invoices.push(dta as InvoiceData);
    });
    cb(invoices);
  });
}

export async function getInvoices() {
  const callable = httpsCallable(functions, "getInvoices");
  const invoices = await callable();

  return invoices;
}

export function invoicesListner(
  users: UserInfos[],
  cb: (invoices: InvoiceData[]) => void
) {
  const map = new Map(users.map((c) => [c.uid, c]));
  return onSnapshot(collection(firestore, "invoices"), (querySnapshot) => {
    const invoices: InvoiceData[] = [];
    querySnapshot.docChanges().forEach((change) => {
      const dta: any = {
        ...change.doc.data(),
        id: change.doc.id,
        uid: change.doc.id,
        created_at:
          change.doc.data().created_at?.toDate()?.getTime() ||
          new Date().getTime(),
      };
      const it = map.get(dta.userId);
      if (it && !it.deleted) {
        dta.userId = it;
        invoices.push(dta as InvoiceData);
      }
    });
    cb(invoices);
  });
}

export async function updateFactStatutAsync(
  uid?: string,
  data?: Partial<InvoiceData>
) {
  if (!uid) return;
  return await updateDoc(doc(firestore, "invoices", uid), data);
}

export async function getInvoicesByUser(id) {
  const callable = httpsCallable(functions, "getInvoicesByUser");
  const invoices = await callable(id);

  return invoices;
}

export async function getComptableInvoices(id) {
  const callable = httpsCallable(functions, "getComptableInvoices");
  const invoices = await callable(id);

  return invoices;
}

export async function sendInvoiceComment({ invoiceId, comment }) {
  const callable = httpsCallable(functions, "sendInvoiceComment");
  const invoiceComment = await callable({ invoiceId, comment });

  return invoiceComment;
}

export async function sendResponseContact({ messageId, response }) {
  const callable = httpsCallable(functions, "sendResponseContact");
  const responseComment = await callable({ messageId, response });

  return responseComment;
}
