import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { AiFillEye } from "react-icons/ai";
import { MdComment } from "react-icons/md";
import Pdf from "../../../../assets/icons/pdf.png";
import {
  getDateFromString,
  getFactureDate,
} from "../../../../redux/slice/invoiceSlice";
import { useAppSelector } from "../../../../redux/store";
import { InvoiceData } from "../../../../redux/types";
import { formatAmout } from "../../../../utils/formatter";
import CommentFactureModal from "../../../modal/comptabilite/CommentFactureModal";
import DetailFactureComptabiliteModal from "../../../modal/comptabilite/DetailFactureComptabiliteModal";
import ListeFactureComtabiliteTableSkeleton from "./ListeFactureComtabiliteTableSkeleton";

export function useCategories(invoices: InvoiceData[]) {
  const categories = useAppSelector((s) => s.categories);
  const getCat = () => {
    const newCat: { [name: string]: string } = {};
    const ids = Array.from(
      new Set(invoices.map((c) => c.categorie).filter((i) => !!i))
    ) as string[];
    for (let id of ids) {
      newCat[id] = categories.find((c) => c.id === id)?.name ?? "";
    }
    return newCat;
  };

  return getCat();
}

function ListeFactureComtabiliteTable({
  invoices,
  isLoading,
  reload,
  userId,
}: {
  invoices: InvoiceData[];
  isLoading: boolean;
  reload: () => any;
  userId: string;
}) {
  const cats = useCategories(invoices);
  const actionFormatter = (cell, row) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal position-relative">
            <button
              className="btn btn-action-icon-chat with-tooltip"
              data-tooltip-content="Commentaire"
              data-bs-toggle="modal"
              data-bs-target={`#CommentFactureModal${row.uid}`}
              id={`bntFactureModal${row.uid}`}
            >
              <MdComment />
            </button>
            <div className="statut-comment-btn">
              {row?.comments ? row?.comments?.length : 0}
            </div>
          </div>
          <div
            className="modal fade"
            id={`CommentFactureModal${row.uid}`}
            aria-labelledby="CommentFactureModalLabel"
            aria-hidden="true"
          >
            <CommentFactureModal
              userId={userId}
              facture={row}
              func={reload}
              invoiceId={row.uid}
              modalId={`CommentFactureModal${row.uid}`}
              btnId={`bntFactureModal${row.uid}`}
            />
          </div>
          <div
            className="modal fade"
            id={`DetailFactureComptabiliteModal${row.uid}`}
            aria-labelledby="DetailFactureComptabiliteModalLabel"
            aria-hidden="true"
          >
            <DetailFactureComptabiliteModal
              facture={row}
              func={reload}
              modalId={`DetailFactureComptabiliteModal${row.uid}`}
              btnId={`bntFactureComptabiliteModal${row.uid}`}
            />
          </div>

          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              data-bs-toggle="modal"
              data-bs-target={`#DetailFactureComptabiliteModal${row?.uid}`}
              id={`bntFactureComptabiliteModal${row.uid}`}
            >
              <AiFillEye />
            </button>
          </div>
        </div>
      </>
    );
  };

  const statutFormatter = (cell, row) => {
    if (row?.traiter) {
      return (
        <div className="table-actions-btn-container-statut-facture">
          <p className="statut-facture-valide mb-0">Validé</p>
        </div>
      );
    } else {
      return (
        <div className="table-actions-btn-container-statut-facture">
          <p className="statut-facture-a-traiter mb-0">À traiter</p>
        </div>
      );
    }
  };

  const typeFormatter = (cell, row) => {
    if (row.type_depense != "Dépense") {
      return (
        <div className="table-actions-btn-container-statut-facture">
          <p className="statut-facture-valide mb-0">{row.type_depense}</p>
        </div>
      );
    } else {
      return (
        <div className="table-actions-btn-container-statut-facture">
          <p className="statut-facture-a-traiter mb-0">{row.type_depense}</p>
        </div>
      );
    }
  };

  const imgFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
        <img
          src={Pdf}
          alt="type document"
          className="fast-facture-img-document-pdf"
        />{" "}
        <span className="ps-2">{row?.commerce_name}</span>
      </div>
    );
  };

  const dateFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
        <span className="ps-2">
          {getDateFromString(row.date, row.created_at).format("DD/MM/YYYY")}{" "}
        </span>
      </div>
    );
  };

  const options = {
    pageStartIndex: 1,
    sizePerPage: 50,
    hidePageListOnlyOnePage: true,
    hideSizePerPage: true,
  };

  const STATUS_OPTIONS = {
    0: "À traiter",
    1: "Validé",
  };

  const TYPE_OPTIONS = {
    Émise: "Émise",
    Dépense: "Dépense",
  };

  const columns = [
    {
      dataField: "commerce_name",
      text: "Facture",
      formatter: (cell: any, row: any) => imgFormatter(cell, row),
      filter: textFilter({ placeholder: "Chercher..." }),
    },
    {
      dataField: "montant_ttc",
      text: "Montant TTC",
      formatter: formatAmout,
      filter: textFilter({ placeholder: "Chercher..." }),
      headerStyle: () => {
        return { width: "150px" };
      },
    },
    {
      dataField: "date",
      text: "Date ",
      filter: textFilter({ placeholder: "Chercher..." }),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "desc") {
          return getFactureDate(rowB) - getFactureDate(rowA);
        }
        return getFactureDate(rowA) - getFactureDate(rowB);
      },
      formatter: (cell: any, row: any) => dateFormatter(cell, row),
    },
    {
      dataField: "traiter",
      text: "Statut",
      filterValue: (cell) => (cell ? 1 : 0),
      filter: selectFilter({
        options: STATUS_OPTIONS,
        placeholder: "Choisir",
      }),
      formatter: (cell: any, row: any) => statutFormatter(cell, row),
    },
    {
      dataField: "categorie",
      text: "Catégorie",
      filter: selectFilter({
        options: cats,
        placeholder: "Choisir",
      }),
      formatter: (cell: any, row: any) => {
        const cat = cats[cell] ?? "";
        row.cat = cat;
        return cat;
      },
    },
    {
      dataField: "type_depense",
      text: "Type",
      formatter: (cell: any, row: any) => typeFormatter(cell, row),
      filter: selectFilter({
        options: TYPE_OPTIONS,
        placeholder: "Choisir",
      }),
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <ListeFactureComtabiliteTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="uid"
            data={invoices}
            columns={columns}
            bordered={true}
            sort={{ dataField: "date", order: "desc" }}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Il ya pas de données"}
            pagination={paginationFactory(options)}
            filter={filterFactory()}
          />
        </>
      )}
    </>
  );
}

export default ListeFactureComtabiliteTable;
