import { useStep } from "react-hooks-helper";
import { useLocation, useNavigate } from "react-router-dom";
import avatar from "../../../../assets/appImages/avatar1.png";
import { useStepType } from "../../../../utils/type";
import TabsClients from "./DetailComptableSteps/TabsClients";
import TabsFactureComptable from "./DetailComptableSteps/TabsFacture";
import TabsInfoPerso from "./DetailComptableSteps/TabsInfoPerso";

const steps = [
  { id: "information", Component: TabsInfoPerso },
  { id: "clients", Component: TabsClients },
  { id: "facture", Component: TabsFactureComptable },
];

const tabs = ["Informations personnelles", "Clients", "Factures"];

const DetailComptable = () => {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const navigate = useNavigate();
  const location: any = useLocation();
  const { state } = location;

  const props = {
    state,
  };

  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="admin-page-title-header-container flex-sb align-items-center">
          <div className="fast-facture-dash-locataire-requetes-header">
            <h4
              className="fast-facture-dash-header-titre linkable"
              onClick={() => navigate(-1)}
            >
              Comptables &gt;{" "}
              <span style={{ fontSize: 16, fontWeight: 400 }}>
                {state?.prenom} {state?.nom}
              </span>
            </h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="bg-white p-3">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex flex-column align-items-center py-4 mb-4 fast-facture-user-avatar-container">
                  <div className="profile-avatar-container">
                    <img
                      src={state?.avatar || avatar}
                      alt="user avatar"
                      className="fast-facture-dash-locataire-param-img-avatar"
                    />
                  </div>

                  <div className="fast-facture-dash-param-title pt-2">
                    {state?.prenom} {state?.nom}
                  </div>
                </div>
              </div>
            </div>
            <div className="container-tabs-user">
              <ul className="tabs-container pt-5 tabs-container-agence">
                {tabs.map((tab, key) => (
                  <li
                    className={`tab-item  ${
                      index === key && "tab-active-item"
                    }`}
                    key={key}
                    onClick={() => go(key)}
                    role="button"
                  >
                    {tab}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="container-page-tabs-user mb-3">
          <div className="bg-white p-3">
            <Component {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailComptable;
