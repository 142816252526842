import VolkenoSkeleton from 'volkeno-react-skeleton'

function ListeReleverComtabiliteTableSkeleton() {
  return (
    <div className="col-md-12">
			<table className="table table-striped">
				<thead>
					<tr>
						
						<th
							scope="col"
							style={{
								textAlign: "justify",
								color: "#010235",
								fontFamily: "Poppins",
								fontSize: "15px",
								fontWeight: "400",
							}}
						>
							Client
						</th>
						
						<th
							scope="col"
							style={{
								textAlign: "justify",
								color: "#010235",
								fontFamily: "Poppins",
								fontSize: "15px",
								fontWeight: "400",
							}}
						>
							Date
						</th>
                       
						<th
							scope="col"
							style={{
								textAlign: "justify",
								color: "#010235",
								fontFamily: "Poppins",
								fontSize: "15px",
								fontWeight: "400",
							}}
						>
							Actions
						</th>
					</tr>
				</thead>
				<tbody>
					
				<tr>
                        <th scope="row">
							<VolkenoSkeleton 
                            height={20}   
                            variant='rect' />
						</th>
						<td>
							<VolkenoSkeleton 
                            height={20}   
                            variant='rect' />
						</td>
						<td>
							<VolkenoSkeleton 
                            height={20}   
                            variant='rect' />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
  )
}

export default ListeReleverComtabiliteTableSkeleton