import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  Categorie,
  CategorieFormData,
  UserInfos,
} from "../../../../../redux/types";
import {
  createUser,
  updateUserAsync,
  useAddCategorie,
} from "../../../../../utils/api/user.api";
import {
  cleannerError,
  onHide,
  uploadUserAvatarAsync,
} from "../../../../../utils/utils";

function UseCrudAdmin(
  modalId: string,
  btnId: string,
  func: any,
  user?: UserInfos
) {
  const validationSchema = yup.object().shape({
    email: yup.string().email().required().label("L'email"),
    prenom: yup.string().required().label("Le prénom"),
    nom: yup.string().required().label("Le nom"),
    entreprise: yup.string().label("L'entreprise"),
    email_entreprise: yup.string().email().label("L'entreprise"),
    permissions: yup.array().of(yup.string()),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<Partial<UserInfos>>({
    resolver: yupResolver(validationSchema),
  });
  const [avatar, setAvatar] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setValue("prenom", user?.prenom);
      setValue("nom", user?.nom);
      setValue("email", user?.email);
      setValue("email_entreprise", user?.email_entreprise);
      setValue("entreprise", user?.entreprise);
      if (user?.permissions) {
        setValue("permissions", user?.permissions);
      }
    }
    return () => {};
  }, [user]);
  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);

  const handleReset = () => {
    if (!user) {
      reset();
    }
  };

  const handleChangeAvatar = async (file) => {
    if (!file) {
      return null;
    }

    if (user?.uid) {
      const url = await uploadUserAvatarAsync(file, user?.uid);
      setValue("avatar", url);
    }
    setAvatar(file);
  };

  const onSubmit = async (data: Partial<UserInfos>) => {
    data.is_admin = true;
    
    data["permissions"] = data.permissions?.filter((p) => p !== "false");
    setIsLoading(true);
    try {
      if (user?.uid) {
        await updateUserAsync(user?.uid, data);
      } else {
        await createUser(data);
      }

      Swal.fire({
        icon: "success",
        title: user
          ? "Modification réussie!"
          : "Administrateur ajouté avec succès!",
        showConfirmButton: false,
        iconColor: "#02B4C0",
        timer: 2000,
      }).then(async () => {
        await func();
        if (!user) {
          reset();
        }

        onHide(modalId, btnId);
        setIsLoading(false);
      });
    } catch (ex) {
      //   setError("email", { message: "Identifiants incorrects" });
    
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Une erreur est survenue!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
    handleChangeAvatar,
    avatar,
  };
}

export default UseCrudAdmin;

export function UseCrudCategorie(
  modalId: string,
  btnId: string,
  item?: Categorie
) {
  const [image, setImage] = useState<File>();
  const { addCat, loading } = useAddCategorie(modalId, btnId);
  const validationSchema = yup.object().shape({
    name: yup.string().required().label("Le Nom"),
    color: yup.string().required().label("La couleur"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<CategorieFormData>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (item) {
      setValue("name", item.name);
      setValue("color", item.color);
      setValue("compte", item.compte ?? "");
    }
    return () => {};
  }, [item]);
  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);

  const handleReset = () => {
    if (!item) {
      reset();
    }
    setImage(undefined);
    document.querySelectorAll("#image").forEach((elm) => {
      // @ts-ignore
      elm.value = "";
    });
  };

  const onSubmit = async (data: CategorieFormData) => {
    if (!item && !image) {
      Swal.fire({
        icon: "error",
        title: "L'image est requise",
        showConfirmButton: false,
        iconColor: "#02B4C0",
        timer: 2000,
      });
      return;
    }
    if (image) {
      data.image = image;
    }
    const res = await addCat(data, item?.id);
    if (res) {
      handleReset();
    }
  };

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading: loading,
    setImage,
    handleReset,
  };
}
