import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInfos } from "../types";

const initialState: UserInfos[] = [];

export const Users2Slice = createSlice({
  name: "users2",
  initialState,
  reducers: {
    onUsers2: (state, action: PayloadAction<UserInfos[]>) => {
      const newState = [...state];
      for (let item of action.payload) {
        const index = newState.findIndex((i) => i.uid === item.uid);
        if (index === -1) {
          newState.push(item);
        } else {
          newState[index] = item;
        }
      }
      return newState;
    }
  },
});

export const { onUsers2 } = Users2Slice.actions;
