import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DashboardData } from "../types";

const initialState: DashboardData = {
  total_facture_emise: 0,
  total_tva_emise: 0,
  total_tva_recuperable: 0,
  total_user_count: 0,
};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    onDashboard: (state, action: PayloadAction<DashboardData>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { onDashboard } = DashboardSlice.actions;
