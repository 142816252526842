import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../redux/store";
import { UserInfos } from "../../../../../redux/types";
import ListeUsersTable from "../../../../tables/admin/ListeUsersTable/ListeUsersTable";

function TabsClients({ state }) {
  const [users, setUsers] = useState<UserInfos[]>([]);
  const allUsers = useAppSelector((s) => s.users);

  useEffect(() => {
    if (state?.uid) {
      const filtred = allUsers.filter(
        (i) => i?.email_comptable === state.email
      );
      setUsers(filtred);
    }
  }, [allUsers.length]);

  return (
    <div className="bg-white p-3">
      <ListeUsersTable is_comptable={false} data={users} />
    </div>
  );
}

export default TabsClients;
