import ListeUsersComtabiliteTable from "../../../tables/comptabilite/ListeUsersComtabiliteTable/ListeUsersComtabiliteTable";
import "./ListeUsersComptabilite.css";

const ListeUsersComptabilite = () => {
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="admin-page-title-header-container flex-sb align-items-center">
          <div className="fast-facture-dash-locataire-requetes-header">
            <h4 className="fast-facture-dash-header-titre">Clients</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="bg-white p-3">
            <ListeUsersComtabiliteTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeUsersComptabilite;
