import { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FiMail } from "react-icons/fi";
import { TbKey } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/appImages/app-logo.png";
import { useAppSelector } from "../../redux/store";
import { isAdminUser } from "../../utils/utils";
import ErrorMessage from "../common/ErrorMessage";
import UseLoginForm from "./Form/UseLoginForm";
import "./Login.css";
const Login = () => {
  const { register, isLoading, errors, onSubmit } = UseLoginForm();
  const { user, userInfos } = useAppSelector((s) => s?.auth);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (userInfos?.uid) {
      const isAdmin = isAdminUser(userInfos);
      if (isAdmin) {
        // if (userInfos.is_superadmin) {
        //   navigate("/super-admin/dashboard", { replace: true });
        // } else {
        navigate("/admin/dashboard", { replace: true });
        // }
      } else {
        navigate("/comptabilite/utilisateurs", { replace: true });
      }
    }
  }, [userInfos]);
  return (
    <div className="auth-component">
      <div className="fixed-login-page">
        <div className="auth-container">
          <div className="auth-row row">
            <div className="col-md-6 auth-col auth-left-side-col">
              <div className="auth-left-side-container">
                <div className="content-logo-app">
                  <img src={Logo} alt="Logo" className="logo-app" />
                </div>
              </div>
            </div>
            <div className="col-md-6 auth-col auth-right-side-col">
              <div className="auth-right-side-container">
                <div className="auth-form-container">
                  <h1 className="auth-form-title">Connectez-vous</h1>
                  <div className="mt-4">
                    <form id="auth-form" onSubmit={onSubmit}>
                      <div className="row auth-form-row">
                        <div className="col-md-12 auth-input-col mb-3">
                          <div className="input-group auth-form-group auth-form-group-login">
                            <span
                              className="input-group-text input-group-text-custom"
                              id="basic-addon1"
                            >
                              <FiMail />
                            </span>
                            <input
                              type={`email`}
                              className="form-control auth-form-control"
                              id="email-or-username"
                              {...register("email")}
                              placeholder="Identifiant"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                          <ErrorMessage message={errors?.email?.message} />
                        </div>
                        <div className="col-md-12 auth-input-col mb-2">
                          <div className="input-group auth-form-group auth-form-group-login">
                            <span
                              className="input-group-text input-group-text-custom"
                              id="basic-addon1"
                            >
                              <TbKey />
                            </span>
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control auth-form-control"
                              id="password"
                              placeholder="Mot de passe"
                              aria-label="Password"
                              {...register("password")}
                              aria-describedby="basic-addon1"
                            />
                            <span
                              className="input-group-text input-group-text-custom"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                              id="basic-addon1"
                            >
                              {!showPassword && <AiFillEye />}
                              {showPassword && <AiFillEyeInvisible />}
                            </span>
                          </div>
                          <ErrorMessage message={errors?.password?.message} />
                        </div>
                        <div className="d-flex justify-content-end mb-3">
                          <Link
                            to={"/mot-de-passe-oublie"}
                            className="forget-password-link"
                          >
                            Mot de passe oublié ?
                          </Link>
                        </div>
                        <div className="col-md-12 auth-submit-btn-container">
                          <button
                            className="btn auth-submit-btn trans-0-2"
                            disabled={isLoading}
                            type="submit"
                          >
                            {isLoading ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                  role="status"
                                ></span>
                                <span>Connexion...</span>
                              </>
                            ) : (
                              "Connexion"
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
