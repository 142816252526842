import React from "react";
import "./Notifications.css";

const Notifications = () => {
   
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page">
                <div className="admin-page-title-header-container">
                    <div className="fast-facture-dash-locataire-requetes-header">
                        <h4 className='fast-facture-dash-header-titre'>Notifications</h4>
                    </div>
                </div>
                <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
                    <div className="bg-white p-3">
                        <div className='content-info-user-entreprise'>
                            <p className="statut-notif-super-admin">Nouveau</p>
                        </div>
                        <div className='content-info-user-entreprise'>
                            <div className='content-text-notif'>
                               <p className="text-notif-super-admin">Vous avez 200 nouvelles utilisateurs <br /> vous avez maintenant 200 000 utilisateurs</p> 
                            </div>
                            <div className='content-date-notif'>
                                <p className='text-date-notif-super-admin'>Ajourd’hui</p>
                            </div>
                        </div>
                        <div className='content-info-user-entreprise b-b-none'>
                            <div className='content-text-notif'>
                               <p className="text-notif-super-admin">Vous avez 200 nouvelles utilisateurs <br /> vous avez maintenant 200 000 utilisateurs</p> 
                            </div>
                            <div className='content-date-notif'>
                                <p className='text-date-notif-super-admin'>Ajourd’hui</p>
                            </div>
                        </div>
                        <div className="autres-notifications mt-4">
                            <div className='content-info-user-entreprise'>
                                <p className="statut-notif-super-admin">Autres</p>
                            </div>
                            <div className='content-info-user-entreprise'>
                                <div className='content-text-notif'>
                                <p className="text-notif-super-admin">Vous avez 200 nouvelles utilisateurs <br /> vous avez maintenant 200 000 utilisateurs</p> 
                                </div>
                                <div className='content-date-notif'>
                                    <p className='text-date-notif-super-admin'>03/09/2022</p>
                                </div>
                            </div>
                            <div className='content-info-user-entreprise'>
                                <div className='content-text-notif'>
                                <p className="text-notif-super-admin">Vous avez 200 nouvelles utilisateurs <br /> vous avez maintenant 200 000 utilisateurs</p> 
                                </div>
                                <div className='content-date-notif'>
                                    <p className='text-date-notif-super-admin'>03/09/2022</p>
                                </div>
                            </div>
                            <div className='content-info-user-entreprise'>
                                <div className='content-text-notif'>
                                <p className="text-notif-super-admin">Vous avez 200 nouvelles utilisateurs <br /> vous avez maintenant 200 000 utilisateurs</p> 
                                </div>
                                <div className='content-date-notif'>
                                    <p className='text-date-notif-super-admin'>03/09/2022</p>
                                </div>
                            </div>
                            <div className='content-info-user-entreprise'>
                                <div className='content-text-notif'>
                                <p className="text-notif-super-admin">Vous avez 200 nouvelles utilisateurs <br /> vous avez maintenant 200 000 utilisateurs</p> 
                                </div>
                                <div className='content-date-notif'>
                                    <p className='text-date-notif-super-admin'>03/09/2022</p>
                                </div>
                            </div>
                            <div className='content-info-user-entreprise'>
                                <div className='content-text-notif'>
                                <p className="text-notif-super-admin">Vous avez 200 nouvelles utilisateurs <br /> vous avez maintenant 200 000 utilisateurs</p> 
                                </div>
                                <div className='content-date-notif'>
                                    <p className='text-date-notif-super-admin'>03/09/2022</p>
                                </div>
                            </div>
                            <div className='content-info-user-entreprise'>
                                <div className='content-text-notif'>
                                <p className="text-notif-super-admin">Vous avez 200 nouvelles utilisateurs <br /> vous avez maintenant 200 000 utilisateurs</p> 
                                </div>
                                <div className='content-date-notif'>
                                    <p className='text-date-notif-super-admin'>03/09/2022</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			 
		</div>
	);
};

export default Notifications;
