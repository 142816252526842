import React from "react";
import CloseModalBtn from "../../shared/CloseModalBtn";
import ErrorMessage from "../../common/ErrorMessage";
import UseCrudComment from "./request/UseCrudComment";
import { InvoiceData } from "../../../redux/types";




const CommentFactureModal = ({
	userId,
	func,
	facture,
	invoiceId,
	modalId,
	btnId,
  }: {
	userId: string ;
	func:any;
	facture: any
	invoiceId: string;
	modalId: string;
	btnId: string;
  }) => {
	const { register, errors, onSubmit, isLoading, handleReset } = UseCrudComment(
		userId,
		func,
	  	invoiceId,
	  	modalId,
	  	btnId,
	);

	
	return (
		<div className="modal-dialog modal-dialog-centered">
			<div className="modal-content py-2">
				<div className="modal-header d-flex justify-content-between align-items-center">
					<h1 className="admin-modal-title text-start">
					Commentaire
					</h1>
					<CloseModalBtn />
				</div>
				<div className="container-form px-3">
					<div className="row my-4 border-bottom content-commentaire">
					{facture?.comments?.map((comment) => (
						<div className="col-md-11 border rounded mb-2 pt-2">
							<p className="text-comment-facture-table">
								{comment}
							</p>
						</div>
					))}
					</div>
					<form onSubmit={onSubmit}>
						<div className="row mb-4">
							<div className="col-12 form-group">
								<label className="admin-form-label mb-2" style={{ display: 'flex' }}>
									Commenter
								</label>
								<textarea {...register("comment")} className='textarrea-comment-custom form-control' placeholder="Ajouter un commentaire" rows={1}></textarea>
                    			<ErrorMessage message={errors?.comment?.message} />
							</div>
						</div>
                        <div className="container-btn-modal row mt-4">
                            <div className="col-md-6 offset-md-6">
								<button
									className="btn auth-submit-btn"
									type="submit"
									disabled={isLoading}
								>
									{!isLoading ? (
									"Envoyer"
									) : (
									<>
										<span
										className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
										role="status"
										></span>
										<span>Enregistrement...</span>
									</>
									)}
								</button>
                            </div>
                        </div>
					</form>
                    
				</div>
			</div>
		</div>
	);
}

export default CommentFactureModal;

