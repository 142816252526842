import { useState } from "react";
import { FiEdit3 } from "react-icons/fi";
import Swal from "sweetalert2";
import avatar from "../../../../assets/appImages/avatar1.png";
import { onSetUserInfos } from "../../../../redux/slice/user.slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { updateUserAsync, userListener } from "../../../../utils/api/user.api";
import { uploadUserAvatarAsync } from "../../../../utils/utils";
import ErrorMessage from "../../../common/ErrorMessage";
import ChangePasswordModal from "../../../modal/admin/ChangePasswordModal";
import "./ProfilAdmin.css";
import UseEditUser from "./request/UseEditUser";

const ProfilAdmin = () => {
  const { userInfos, user } = useAppSelector((s) => s?.auth);
  const { register, errors, onSubmit, isLoading } = UseEditUser();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleChangeAvatar = async (file) => {
    if (!file) {
      return null;
    }

    setLoading(true);
    try {
      const url = await uploadUserAvatarAsync(file, userInfos?.uid);

      await updateUserAsync(userInfos?.uid, { avatar: url });
      if (user) {
        userListener(user, (userInfos) => {
          dispatch(onSetUserInfos(userInfos));
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Modification réussie!",
            showConfirmButton: false,
            iconColor: "#02B4C0",
            timer: 2000,
          });
        });
      } else {
        setLoading(false);
      }
    } catch (ex: any) {
      
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Une erreur est survenue!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  return (
    <div className="profil-page-component">
      <div className="bg-white p-3 mt-4 filter-and-table-container">
        <section className="mb-3">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-column align-items-center py-4 mb-4 fast-facture-user-avatar-container">
                <div className="mb-auto profile-image-admin">
                  <div className="profile-avatar-container">
                    <img src={userInfos?.avatar || avatar} alt="avatar" />
                    <span style={loading ? { bottom: "0%" } : {}}>
                      <i className="fas fa-camera"></i>
                      <p>
                        {loading ? (
                          <i
                            className="spinner-border spinner-border-sm  me-1 d-inline-block"
                            role="status"
                          ></i>
                        ) : (
                          "Changer"
                        )}
                      </p>
                      <input
                        type="file"
                        onChange={(e: any) => {
                          let file = e.target.files[0];
                          handleChangeAvatar(file);
                        }}
                        accept="image/*"
                        disabled={loading}
                        name="avatar"
                        id="file_up"
                      />
                    </span>
                  </div>
                </div>
                <div className="fast-facture-dash-param-title pt-2">
                  {userInfos?.prenom}&nbsp;{userInfos?.nom}
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3 position-relative">
                  <label
                    htmlFor="nom"
                    className="form-label form-label-modal-custom"
                  >
                    Prénom
                  </label>
                  <input
                    type="text"
                    placeholder="Prénom"
                    {...register("prenom")}
                    className="form-control form-control-modal-custom form-control-params-custom"
                    id="Prénom"
                  />
                  <div className="content-icon-edit-info-profil">
                    <FiEdit3 />
                  </div>
                  <ErrorMessage message={errors?.prenom?.message} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3 position-relative">
                  <label
                    htmlFor="prenom"
                    className="form-label form-label-modal-custom"
                  >
                    Nom
                  </label>
                  <input
                    type="text"
                    placeholder="Nom"
                    {...register("nom")}
                    className="form-control form-control-modal-custom form-control-params-custom"
                    id="Nom"
                  />
                  <div className="content-icon-edit-info-profil">
                    <FiEdit3 />
                  </div>
                  <ErrorMessage message={errors?.nom?.message} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3 position-relative">
                  <label
                    htmlFor="email"
                    className="form-label form-label-modal-custom"
                  >
                    Adresse mail
                  </label>
                  <input
                    type="email"
                    placeholder="lopezsara@gmail.com"
                    className="form-control form-control-modal-custom form-control-params-custom"
                    id="email"
                    {...register("email")}
                  />
                  <div className="content-icon-edit-info-profil">
                    <FiEdit3 />
                  </div>
                  <ErrorMessage message={errors?.email?.message} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <button
                  className="btn auth-submit-btn"
                  disabled={isLoading}
                  type="submit"
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>Enregistrement...</span>
                    </>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
      <div className="bg-white p-3 my-4 filter-and-table-container">
        <div className="row">
          <div className="col-md-4">
            <div className="password-btn-container">
              <h6 className="form-label-modal-custom">Mot de Passe</h6>
              <button
                className="btn auth-submit-btn"
                data-bs-toggle="modal"
                data-bs-target="#changePassword"
                id="btnAdminEditPassword"
              >
                Changer votre mot de passe
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="changePassword"
        aria-labelledby="changePasswordLabel"
        aria-hidden="true"
      >
        <ChangePasswordModal />
      </div>
    </div>
  );
};

export default ProfilAdmin;
