import "intl";
import "intl/locale-data/jsonp/fr";

export function formatAmout2(amount?: string | number) {
  if (amount) {
    return new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }).format(parseFloat(String(amount)));
  }

  return "0 €";
}

export function formatAmout(amount?: string | number) {
  if (!amount) return "0€";
  if (typeof amount === "string") amount = parseFloat(amount);
  return amount.toFixed(2) + "€";
}
