import React from "react";
import { UserInfos } from "../../../redux/types";
import ErrorMessage from "../../common/ErrorMessage";
import CloseModalBtn from "../../shared/CloseModalBtn";
import UseCrudUser from "../../TableauDebord/Admin/ListeUsers/request/UseCrudUser";
import "./AdminModal.css";

const AddUserModal = ({
  func,
  user,
  modalId,
  btnId,
}: {
  func: any;
  user?: UserInfos;
  modalId: string;
  btnId: string;
}) => {
  const { register, errors, onSubmit, isLoading, handleReset } = UseCrudUser(
    modalId,
    btnId,
    func,
    user
  );
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content text-start">
        <div className="modal-header py-2 flex-m">
          <h5 className="admin-modal-title text-start">
            {!user ? "Ajouter" : "Modifier"} un utilisateur
          </h5>
          <CloseModalBtn onClick={() => handleReset()} />
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="prenom"
                      className="form-label form-label-modal-custom"
                    >
                      Prénom <span className="badge-danger">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      className="form-control form-control-modal-custom"
                      id="prenom"
                      {...register("prenom")}
                    />
                    <ErrorMessage message={errors?.prenom?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="nom"
                      className="form-label form-label-modal-custom"
                    >
                      Nom<span className="badge-danger">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Nom"
                      className="form-control form-control-modal-custom"
                      id="nom"
                      {...register("nom")}
                    />
                    <ErrorMessage message={errors?.nom?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="email"
                      className="form-label form-label-modal-custom"
                    >
                      Email <span className="badge-danger">*</span>
                    </label>
                    <input
                      type="email"
                      placeholder="Example@gmail.com"
                      className="form-control form-control-modal-custom"
                      id="email"
                      {...register("email")}
                    />
                    <ErrorMessage message={errors?.email?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="entreprise"
                      className="form-label form-label-modal-custom"
                    >
                      Entreprise
                    </label>
                    <input
                      type="text"
                      placeholder="Entreprise"
                      className="form-control form-control-modal-custom"
                      id="entreprise"
                      {...register("entreprise")}
                    />
                    <ErrorMessage message={errors?.entreprise?.message} />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="email"
                      className="form-label form-label-modal-custom"
                    >
                      Email entreprise
                    </label>
                    <input
                      type="email"
                      placeholder="Example@gmail.com"
                      className="form-control form-control-modal-custom"
                      id="email"
                      {...register("email_entreprise")}
                    />
                    <ErrorMessage message={errors?.email_entreprise?.message} />
                  </div>
                </div>
              </div>
              <div className="container-btn-modal row mt-4">
                <div className="col-md-4">
                  <button
                    className="btn auth-submit-btn"
                    type="submit"
                    disabled={isLoading}
                  >
                    {!isLoading ? (
                      "Envoyer"
                    ) : (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>Enregistrement...</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
