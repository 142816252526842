import React from "react";
import "./ParametreSuperAdmin.css";
import { IoMdAdd } from "react-icons/io";
import AddAdminModal from "../../../modal/admin/AddAdminModal";
import ListeSuperAdminsTable from "../../../tables/superAdmin/ListeSuperAdminsTable/ListeSuperAdminsTable";
import ListeAdminsTable from "../../../tables/admin/ListeAdminsTable/ListeAdminsTable";

const ParametreSuperAdmin = () => {
   
	return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="admin-page-title-header-container flex-sb align-items-center">
          <div className="fast-facture-dash-locataire-requetes-header">
            <h4 className="fast-facture-dash-header-titre">Administrateurs</h4>
          </div>
          <div className="content-btn-add-theme">
            <button
              className="btn btn-add-theme"
              data-bs-toggle="modal"
              data-bs-target="#AddAdminModal"
            >
              <IoMdAdd />
              <span className="ps-2">Ajouter un administrateur</span>
            </button>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="bg-white p-3">
            <ListeAdminsTable />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddAdminModal"
        aria-labelledby="AddAdminModalLabel"
        aria-hidden="true"
      >
        <AddAdminModal
          modalId="AddAdminModal"
          func={() => {}}
          btnId="btnAdminAddAdmin"
        />
      </div>
    </div>
  );
};

export default ParametreSuperAdmin;
