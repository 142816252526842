import { useAppSelector } from "../../../../redux/store";
import ExcelExport from "../../../../utils/Excelexport";
import { getExportData } from "../../../../utils/api/user.api";
import { useInvoices } from "../../../tables/admin/FacturesRecentesTable/FacturesRecentesTable";
import FacturesTable from "../../../tables/admin/FacturesTable/FacturesTable";
import "./ListeFactures.css";

const ListeFactures = () => {
  const { invoices, refetch, loading } = useInvoices();
  const categories = useAppSelector((s) => s.categories);
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="admin-page-title-header-container">
          <div className="fast-facture-dash-locataire-requetes-header">
            <h4 className="fast-facture-dash-header-titre">Factures</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="bg-white p-3">
            <div className="flex-sb align-items-center mb-4">
              <div className="tab-dash-admin-filter-container"></div>
              <ExcelExport
                getFileData={() => getExportData(invoices, categories)}
                fileName={`Factures_${new Date().getTime()}`}
              />
            </div>
            <FacturesTable
              data={invoices}
              isLoading={loading}
              func={() => refetch()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeFactures;
