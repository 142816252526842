import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppStorage } from "../../utils/userStorage";
import { Auth } from "../types";

const initialState: Auth = AppStorage.getItem("auth", {
  user: null,
  userInfos: null,
});

export const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    onSetUser: (state, action: PayloadAction<Auth["user"]>) => {
      state.user = action.payload;
      AppStorage.setItem("auth", state);
    },
    onSetUserInfos: (state, action: PayloadAction<Auth["userInfos"]>) => {
      state.userInfos = action.payload;
      AppStorage.setItem("auth", state);
    },
    onLogout: (state) => {
      state.user = null;
      state.userInfos = null;
      AppStorage.setItem("auth", state);
    },
  },
});

export const { onLogout, onSetUser, onSetUserInfos } = userSlice.actions;
