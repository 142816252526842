export type FieldType =
  | "dashboard"
  | "utilisateurs"
  | "entreprises"
  | "factures"
  | "messages"
  | "parametres";
type Authorization = {
  value: FieldType;
  label: string;
  path: string;
};
export const Authorisations: Authorization[] = [
  {
    value: "dashboard",
    label: "Dashboard",
    path: "/admin/dashboard",
  },
  {
    value: "utilisateurs",
    label: "Clients",
    path: "/admin/utilisateurs",
  },
  {
    value: "entreprises",
    label: "Entreprise",
    path: "/admin/proprietaires",
  },
  {
    value: "factures",
    label: "Factures",
    path: "/admin/factures",
  },
  {
    value: "messages",
    label: "Messagerie",
    path: "/admin/messagerie",
  },
  {
    value: "parametres",
    label: "Paramètres",
    path: "/admin/parametres",
  },
];
