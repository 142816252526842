import { useLocation, useNavigate } from "react-router-dom";
import avatar from "../../../../assets/appImages/avatar1.png";
import { Authorisations } from "../../../../utils/Constantes";

const DetailParametre = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { state } = location;

  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="admin-page-title-header-container flex-sb align-items-center">
          <div className="fast-facture-dash-locataire-requetes-header">
            <h4
              className="fast-facture-dash-header-titre linkable"
              onClick={() => navigate(-1)}
            >
              Paramètres &gt;{" "}
              <span style={{ fontSize: 16, fontWeight: 400 }}>
                Detail admin
              </span>
            </h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="bg-white p-3">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex flex-column align-items-center py-4 mb-4 fast-facture-user-avatar-container">
                  <img
                    src={state?.avatar || avatar}
                    alt="user avatar"
                    className="fast-facture-dash-locataire-param-img-avatar"
                  />
                  <div className="fast-facture-dash-param-title pt-2">
                    {state?.prenom} {state?.nom}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-page-tabs-user mb-3">
          <div className="bg-white p-3">
            <div className="row row-info-perso-user">
              <div className="col-md-6">
                <div className="content-text-info-user">
                  <p className="text-info-user-libelle">Prénom </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="content-text-info-user">
                  <p className="text-info-user-value">{state?.prenom}</p>
                </div>
              </div>
            </div>

            <div className="row row-info-perso-user">
              <div className="col-md-6">
                <div className="content-text-info-user">
                  <p className="text-info-user-libelle">Nom </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="content-text-info-user">
                  <p className="text-info-user-value">{state?.nom} </p>
                </div>
              </div>
            </div>

            <div className="row row-info-perso-user">
              <div className="col-md-6">
                <div className="content-text-info-user">
                  <p className="text-info-user-libelle">Mail </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="content-text-info-user">
                  <p className="text-info-user-value">{state?.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-page-tabs-user mb-3">
          <div className="bg-white p-3">
            <div className="col-md-12 mt-3">
              <div className="form-group crud-form-group content">
                <label
                  htmlFor="fixe"
                  className="form-label form-label-modal-custom"
                >
                  Accès
                </label>
                <div className="container-radio-input">
                  <div className="form-radio-input radio-input">
                    {Authorisations?.map((el, i) => (
                      <label className="ml-0" key={i} htmlFor={el?.value}>
                        <input
                          type="checkbox"
                          name={el?.value}
                          value={el?.value}
                          id={el?.value}
                          readOnly
                          checked={
                            state?.permissions &&
                            state?.permissions?.length &&
                            state?.permissions?.includes(el?.value)
                          }
                        />
                        <span>{el?.label}</span>
                      </label>
                    ))}

                    {/* <label htmlFor="users">
                      <input
                        type="checkbox"
                        name="users"
                        value="users"
                        id="users"
                      />
                      <span>Clients</span>
                    </label>
                    <label htmlFor="entreprise">
                      <input
                        type="checkbox"
                        name="entreprise"
                        value="entreprise"
                        id="entreprise"
                      />
                      <span>Entreprises</span>
                    </label>
                    <label htmlFor="facture">
                      <input
                        type="checkbox"
                        name="facture"
                        value="facture"
                        id="facture"
                      />
                      <span>Factures</span>
                    </label>
                    <label htmlFor="message">
                      <input
                        type="checkbox"
                        name="message"
                        value="message"
                        id="message"
                      />
                      <span>Messages</span>
                    </label>
                    <label htmlFor="parametres">
                      <input
                        type="checkbox"
                        name="parametres"
                        value="parametres"
                        id="parametres"
                      />
                      <span>Paramètres</span>
                    </label> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-btn-parametre flex-r my-4 gap-3">
          <div className="content-btn-cancelled">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="btn btn-cancelled"
            >
              Retour
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailParametre;
