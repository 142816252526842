import moment from "moment";

function TabsInfoPerso({ state }) {
  return (
    <div className="bg-white p-3 filter-and-table-container">
      <div className="row row-info-perso-user">
        <div className="col-md-6">
          <div className="content-text-info-user">
            <p className="text-info-user-libelle">Nom </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-text-info-user">
            <p className="text-info-user-value">
              {state?.prenom} {state?.nom}{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="row row-info-perso-user">
        <div className="col-md-6">
          <div className="content-text-info-user">
            <p className="text-info-user-libelle">Entreprise </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-text-info-user">
            <p className="text-info-user-value">
              {state?.entreprise || "Non renseigné"}
            </p>
          </div>
        </div>
      </div>
      <div className="row row-info-perso-user">
        <div className="col-md-6">
          <div className="content-text-info-user">
            <p className="text-info-user-libelle">Email Entreprise</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-text-info-user">
            <p className="text-info-user-value">{state?.email}</p>
          </div>
        </div>
      </div>
      <div className="row row-info-perso-user">
        <div className="col-md-6">
          <div className="content-text-info-user">
            <p className="text-info-user-libelle">Email Comptable</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-text-info-user">
            <p className="text-info-user-value">
              {state?.email_comptable || "Non renseigné"}
            </p>
          </div>
        </div>
      </div>
      <div className="row row-info-perso-user">
        <div className="col-md-6">
          <div className="content-text-info-user">
            <p className="text-info-user-libelle">Date inscription client</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-text-info-user">
            <p className="text-info-user-value">
              {moment(state.created_at).format("DD/MM/YYYY")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabsInfoPerso;
