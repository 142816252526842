import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Categorie } from "../types";

const initialState: Categorie[] = [];

export const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    onGetCategories: (state, action: PayloadAction<Categorie[]>) => {
      const newState = [...state];
      for (let item of action.payload) {
        const index = newState.findIndex((i) => i.id === item.id);
        if (index === -1) {
          newState.push(item);
        } else {
          newState[index] = item;
        }
      }
      return newState;
    },
    onDeleteCategories: (state, action: PayloadAction<string[]>) => {
      return state.filter((f) => !action.payload.includes(f.id));
    },
  },
});

export const { onGetCategories, onDeleteCategories } = categorySlice.actions;
