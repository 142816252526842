import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import _ from "lodash";
import "moment/locale/fr";
import { useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import Swal from "sweetalert2";
import ChangePasswordModal from "./components/modal/admin/ChangePasswordModal";
import {
  onDeleteCategories,
  onGetCategories,
} from "./redux/slice/categorySlice";
import { onGetContent } from "./redux/slice/content.slice";
import { onInvoices } from "./redux/slice/invoiceSlice";
import { onMessageContact } from "./redux/slice/message-contact.slice";
import { onNotifications } from "./redux/slice/notification.slice";
import { onLogout, onSetUser, onSetUserInfos } from "./redux/slice/user.slice";
import { onDeleteUsers, onUsers } from "./redux/slice/users.slice";
import { onUsers2 } from "./redux/slice/users2.slice";
import { useAppDispatch, useAppSelector } from "./redux/store";
import { AppRoutes } from "./routes/router";
import { authListener, logoutAsync } from "./utils/api/auth.api";
import { invoicesListner } from "./utils/api/invoice.api";
import {
  CategorieListener,
  contentListener,
  messageContactListener,
  notificationListener,
  userListener,
  usersComptableListener,
  usersListener,
} from "./utils/api/user.api";
import { isAdminUser } from "./utils/utils";

function App() {
  const routes = useRoutes(AppRoutes);
  const dispatch = useAppDispatch();
  const users = useAppSelector((s) => s.users);
  const auth = useAppSelector((s) => s.auth);

  const isAdmin = isAdminUser(auth?.userInfos);
  const location = useLocation();

  useEffect(() => {
    const subs = authListener((u) => {
      if (u) {
        const userData: any = _.pick(u, [
          "uid",
          "email",
          "emailVerified",
          "displayName",
          "phoneNumber",
          "photoURL",
        ]);
        dispatch(onSetUser(userData));
      } else {
        dispatch(onLogout());
      }
    });
    return () => {
      subs();
    };
  }, []);

  useEffect(() => {
    const length = users.length;
    if (length > 0 && isAdmin) {
      const listner2 = invoicesListner(users, (data) => {
        dispatch(onInvoices(data));
      });
      return () => {
        listner2();
      };
    }
  }, [users.length, isAdmin]);

  useEffect(() => {
    if (auth.user?.uid) {
      const u1 = userListener(auth.user, (userInfos) => {
        if (
          userInfos?.is_admin ||
          userInfos?.is_comptable ||
          userInfos?.is_superadmin
        ) {
          dispatch(onSetUserInfos(userInfos));
        } else {
          Swal.fire({
            icon: "error",
            title: "Connexion non autorisée!",
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            dispatch(onLogout());
            logoutAsync();
          });
        }
      });
      return () => {
        u1();
      };
    }
  }, [auth.user?.uid]);

  useEffect(() => {
    if (isAdmin) {
      const listner = usersListener(
        (data) => {
          dispatch(onUsers(data));
        },
        (dels) => {
          setTimeout(() => {
            dispatch(onDeleteUsers(dels));
          }, 1200);
        }
      );
      const listner1 = messageContactListener((data) => {
        dispatch(onMessageContact(data));
      });

      return () => {
        listner();
        listner1();
      };
    }
  }, [isAdmin]);

  useEffect(() => {
    if (auth?.userInfos?.is_comptable) {
      const listner = usersComptableListener(
        auth.userInfos.email,
        (data) => {
          dispatch(onUsers2(data));
        },
        (dels) => {}
      );
      return () => listner();
    }
  }, [auth?.userInfos]);

  useEffect(() => {
    if (auth?.userInfos?.uid) {
      const listner = notificationListener(auth?.userInfos?.uid, (data) => {
        dispatch(onNotifications(data));
      });
      const listner1 = CategorieListener((data, deles) => {
        dispatch(onGetCategories(data));
        if (deles.length > 0) {
          setTimeout(() => {
            dispatch(onDeleteCategories(deles));
          }, 400);
        }
      });
      return () => {
        listner();
        listner1();
      };
    }
  }, [auth?.userInfos?.uid]);

  useEffect(() => {
    if (auth?.userInfos?.uid) {
      if (auth.userInfos.isDefaultPassword) {
        setTimeout(() => {
          $(`#btnUpdatePassword`).click();
        }, 1000);
      }
    }
  }, [auth?.userInfos?.uid]);

  useEffect(() => {
    contentListener((data) => {
      dispatch(onGetContent(data));
    });
  }, []);

  const modalId = location.pathname?.includes("profil")
    ? "changePassword"
    : "changePasswordApp";

  return (
    <div className="app-routing-container">
      <ScrollToTop />
      {routes}

      <div
        className="modal fade"
        id="changePasswordApp"
        aria-labelledby="changePasswordAppLabel"
        aria-hidden="true"
      >
        <ChangePasswordModal modalId="changePasswordApp" />
      </div>
      <button
        className="btn auth-submit-btn"
        data-bs-toggle="modal"
        data-bs-target={`#${modalId}`}
        id="btnUpdatePassword"
        style={{ visibility: "hidden" }}
      >
        Changer votre mot de passe
      </button>
    </div>
  );
}

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.state]);
  return null;
}
export default App;
