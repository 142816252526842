import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { CommentFormData } from "../../../../redux/types";
import { sendInvoiceComment } from "../../../../utils/api/invoice.api";
import { cleannerError, onHide } from "../../../../utils/utils";


function UseCrudComment(userId: string ,func: any ,invoiceId: string , modalId:string, btnId: string ) {
  const validationSchema = yup.object().shape({
    comment: yup.string().required().label("Le commentaire"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<Partial<CommentFormData>>({
    resolver: yupResolver(validationSchema),
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);


  const handleReset = () => {
      reset();
  };


  const onSubmit = async (data: Partial<CommentFormData>) => {
    setIsLoading(true);
    try {

      let comment = data.comment
      
    await sendInvoiceComment({invoiceId, comment })
      
      await Swal.fire({
        icon: "success",
        title:"Commentaire ajouté avec succès!",
        showConfirmButton: false,
        iconColor: "#02B4C0",
        timer: 2000,
      }).then(async () => {
        await func(userId);
        await onHide(modalId, btnId); 
        setIsLoading(false);
        handleReset();
      });
    } catch (ex) {
      //   setError("email", { message: "Identifiants incorrects" });
      
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Une erreur est survenue!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
  };
}

export default UseCrudComment;
