import $ from "jquery";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import avatar from "../../../../assets/appImages/user.png";
import Factures from "../../../../assets/icons/facture.png";
import TvaCollecte from "../../../../assets/icons/tva-collecte.png";
import TvaEmise from "../../../../assets/icons/tva-emise.png";
import Users from "../../../../assets/icons/users.png";
import {
  getDateFromString,
  getFactureDate,
} from "../../../../redux/slice/invoiceSlice";
import { useAppSelector } from "../../../../redux/store";
import { Categorie, InvoiceData, UserInfos } from "../../../../redux/types";
import ExcelExport from "../../../../utils/Excelexport";
import {
  InvoicesListener,
  getExportData,
} from "../../../../utils/api/user.api";
import { formatNumber } from "../../../../utils/utils"
import { useUserCategories } from "../../../../utils/utils";
import ListeFactureComtabiliteTable from "../../../tables/comptabilite/ListeFactureComtabiliteTable/ListeFactureComtabiliteTable";
import { CardSkeleton } from "../../Admin/Dashboard/DashboardAdmin";
import DonutChart from "./DonutChart";

const DetailUserComptabilite = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [invoices, setInvoices] = useState<InvoiceData[]>([]);
  const categories = useAppSelector((s) => s.categories);
  const [params] = useSearchParams();
  const invoiceId = params.get("invoiceId");
  const [_, setShowed] = useState(false);
  const [dashboard, setDashboard] = React.useState({
    total_facture_depenses: 0,
    total_tve_recuperable: 0,
    total_facture_emises: 0,
    total_tva_emises: 0,
  });
  React.useEffect(() => {
    let deps: InvoiceData[] = [];
    let emises: InvoiceData[] = [];
    for (let i of invoices) {
      if (i.type_depense === "Émise") emises.push(i);
      else deps.push(i);
    }
    const sumHt = (acc: number, cur: InvoiceData) =>
      acc + parseFloat(cur.montant_ht?.toString());
    const sumTva = (acc: number, cur: InvoiceData) =>
      acc + parseFloat(cur.tva?.toString());

    setDashboard({
      total_facture_depenses: deps.reduce(sumHt, 0),
      total_tve_recuperable: deps.reduce(sumTva, 0),
      total_facture_emises: emises.reduce(sumHt, 0),
      total_tva_emises: emises.reduce(sumTva, 0),
    });
  }, [invoices]);
  const navigate = useNavigate();
  const location: any = useLocation();
  const { state } = location as { state: UserInfos };

  useEffect(() => {
    if (state?.uid) {
      const lustener = InvoicesListener(state.uid, (data) => {
        setIsLoading(false);
        setInvoices((oldState) => {
          const newState = [...oldState];
          for (let item of data) {
            const index = newState.findIndex((i) => i.id === item.id);
            if (index === -1) {
              item.userId = state;
              newState.push(item);
            } else {
              newState[index] = item;
            }
          }
          return newState.sort((a, b) => getFactureDate(b) - getFactureDate(a));
        });
      });
      return () => lustener();
    }
  }, [state?.uid]);
  useEffect(() => {
    if (invoiceId) {
      setShowed((old) => {
        if (!old) {
          setTimeout(() => {
            $(`#bntFactureComptabiliteModal${invoiceId}`)?.click();
          }, 1200);
        }
        return true;
      });
    }
  }, [invoiceId, state?.uid]);

  useEffect(() => {
    if (state?.uid) {
      setShowed(false);
    } else {
      navigate("/comptabilite/utilisateurs");
    }
  }, [state?.uid]);

  if (!state) return null;

  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="admin-page-title-header-container flex-sb align-items-center">
          <div className="fast-facture-dash-locataire-requetes-header">
            <h4
              className="fast-facture-dash-header-titre linkable"
              onClick={() => navigate(-1)}
            >
              Clients &gt;
              <span style={{ fontSize: 16, fontWeight: 400 }}>
                {state?.prenom} {state?.nom}
              </span>
            </h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="bg-white p-3">
            <div className="row mb-4">
              <div className="col-md-12">
                <div className="d-flex flex-column align-items-center py-4 mb-4 fast-facture-user-avatar-container">
                  <div className="profile-avatar-container">
                    <img
                      src={state?.avatar || avatar}
                      alt="user avatar"
                      className="fast-facture-dash-locataire-param-img-avatar"
                    />
                  </div>
                  <div className="fast-facture-dash-param-title pt-2">
                    {state?.prenom} {state?.nom}
                  </div>
                  <p className="text-entreprise-user-comptabilite">
                    {state?.entreprise}
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              {isLoading ? (
                <CardSkeleton />
              ) : (
                <>
                  <div className="col-lg-3 col-md-12 mb-3 dis-flex px-md-2">
                    <div className="item-stat-dashboad-view">
                      <div className="content-stat-dashboard-view">
                        <div className="content-text-item-stat-dashboad-view ms-3">
                          <div className="content-item-stat-dashboad-view mt-2">
                            <p className="titre-item-stat-dashboad-view mb-1">
                              Total factures dépenses
                            </p>
                          </div>
                          <div className="icon-item-stat-dashboad-view">
                            <p className="chiffre-item-stat-dashboad-view mb-1">
                              {" "}
                              {formatNumber(dashboard.total_facture_depenses)}
                            </p>
                          </div>
                        </div>
                        <div className="content-item-icon-stat-dashboard-view">
                          <img
                            src={Factures}
                            alt="icon"
                            className="icon-item-stat-dashboad-view"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 mb-3 dis-flex px-md-2">
                    <div className="item-stat-dashboad-view">
                      <div className="content-stat-dashboard-view">
                        <div className="content-text-item-stat-dashboad-view ms-3">
                          <div className="content-item-stat-dashboad-view mt-2">
                            <p className="titre-item-stat-dashboad-view mb-1">
                              Total TVA récupérable{" "}
                            </p>
                          </div>
                          <div className="icon-item-stat-dashboad-view">
                            <p className="chiffre-item-stat-dashboad-view mb-1">
                              {formatNumber(dashboard?.total_tve_recuperable)}
                            </p>
                          </div>
                        </div>
                        <div className="content-item-icon-stat-dashboard-view">
                          <img
                            src={TvaCollecte}
                            alt="icon"
                            className="icon-item-stat-dashboad-view"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 mb-3 dis-flex px-md-2">
                    <div className="item-stat-dashboad-view">
                      <div className="content-stat-dashboard-view">
                        <div className="content-text-item-stat-dashboad-view ms-3">
                          <div className="content-item-stat-dashboad-view mt-2">
                            <p className="titre-item-stat-dashboad-view mb-1">
                              Total factures émises
                            </p>
                          </div>
                          <div className="icon-item-stat-dashboad-view">
                            <p className="chiffre-item-stat-dashboad-view mb-1">
                              {formatNumber(dashboard?.total_facture_emises)}
                            </p>
                          </div>
                        </div>
                        <div className="content-item-icon-stat-dashboard-view">
                          <img
                            src={Users}
                            alt="icon"
                            className="icon-item-stat-dashboad-view"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 mb-3 dis-flex px-md-2">
                    <div className="item-stat-dashboad-view">
                      <div className="content-stat-dashboard-view">
                        <div className="content-text-item-stat-dashboad-view ms-3">
                          <div className="content-item-stat-dashboad-view mt-2">
                            <p className="titre-item-stat-dashboad-view mb-1">
                              Total TVA Collectée
                            </p>
                          </div>
                          <div className="icon-item-stat-dashboad-view">
                            <p className="chiffre-item-stat-dashboad-view mb-1">
                              {formatNumber(dashboard?.total_tva_emises)}
                            </p>
                          </div>
                        </div>
                        <div className="content-item-icon-stat-dashboard-view">
                          <img
                            src={TvaEmise}
                            alt="icon"
                            className="icon-item-stat-dashboad-view"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row">
              <CircleGraph userId={state.uid as string} invoices={invoices} />
            </div>
            <div className="flex-sb align-items-center mb-4">
              <div className="tab-dash-admin-filter-container"></div>
              <ExcelExport
                getFileData={() => getExportData(invoices, categories)}
                fileName={`Factures_${new Date().getTime()}`}
              />
            </div>
            <ListeFactureComtabiliteTable
              invoices={invoices}
              isLoading={isLoading}
              reload={() => null}
              userId={state.uid as string}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const listFilters = [
  {
    label: "Semaine",
    value: "week",
  },
  {
    label: "Mois",
    value: "month",
  },
  {
    label: "Année",
    value: "year",
  },
];

type GraphData = { cat: Categorie; count: number };
type GraphFilter = "week" | "month" | "year";
function CircleGraph({
  invoices,
  userId,
}: {
  invoices: InvoiceData[];
  userId: string;
}) {
  const [filter, setFilter] = useState<GraphFilter>("week");
  const categories = useUserCategories(userId);
  const [data, setData] = useState<GraphData[]>([]);

  useEffect(() => {
    const start = moment().startOf(filter);
    const end = moment().endOf(filter);

    const filtred = invoices.filter((i) => {
      const date = getDateFromString(i.date, i.created_at);
      return start <= date && end >= date;
    });
    const noCat = filtred.filter((f) => !f.categorie).length;
    const dt = categories.map((c) => {
      const count = filtred.filter((c2) => c2.categorie === c.id).length;
      return {
        cat: c,
        count: count,
      };
    });

    if (noCat > 0) {
      dt.push({
        cat: { color: "#000", id: "1", name: "Non catégorisé" },
        count: noCat,
      });
    }
    setData(dt.sort((a, b) => b.count - a.count));
  }, [categories, filter, invoices]);

  return (
    <div className="circle-graph-container mt-2 mb-2 px-3 py-3">
      <div className="d-flex flex-column align-items-center flex-sm-row justify-content-between">
        <h3 className="graph-title">
          Reporting des factures par rapport aux categories{" "}
        </h3>
        <ul className="list-filters d-flex p-0">
          {listFilters.map((item, i) => (
            <li
              key={item.value}
              role="button"
              className={`filter-item ${filter === item.value ? "active" : ""}`}
              onClick={() => setFilter(item?.value as GraphFilter)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </div>
      <div className="pt-3">
        <DonutChart data={data} />
      </div>
    </div>
  );
}

export default DetailUserComptabilite;
