import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { UserInfos } from "../../../../../redux/types";
import { createUser, updateUserAsync } from "../../../../../utils/api/user.api";
import { cleannerError, onHide } from "../../../../../utils/utils";

function UseCrudUser(
  modalId: string,
  btnId: string,
  func: any,
  user?: UserInfos
) {
  const validationSchema = yup.object().shape({
    email: yup.string().email().required().label("L'email"),
    prenom: yup.string().required().label("Le prénom"),
    nom: yup.string().required().label("Le nom"),
    entreprise: yup.string().label("L'entreprise"),
    email_entreprise: yup.string().email().label("L'entreprise"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<Partial<UserInfos>>({
    resolver: yupResolver(validationSchema),
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (user) {
      setValue("prenom", user?.prenom);
      setValue("nom", user?.nom);
      setValue("email", user?.email);
      setValue("email_entreprise", user?.email_entreprise);
      setValue("entreprise", user?.entreprise);
    }
    return () => {};
  }, [user]);
  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);

  const handleReset = () => {
    if (!user) {
      reset();
    }
  };

  const onSubmit = async (data: Partial<UserInfos>) => {
    setIsLoading(true);
    try {
      if (user?.uid) {
        
        await updateUserAsync(user?.uid, data);
      } else {
        await createUser(data);
      }

      await Swal.fire({
        icon: "success",
        title: user ? "Modification réussie!" : "Client ajouté avec succès!",
        showConfirmButton: false,
        iconColor: "#02B4C0",
        timer: 2000,
      }).then(async () => {
        await onHide(modalId, btnId);
        await func();

        setIsLoading(false);
        handleReset();
      });
    } catch (ex) {
      //   setError("email", { message: "Identifiants incorrects" });
      
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Une erreur est survenue!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
  };
}

export default UseCrudUser;
